import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { externalSbatSaveSucceeded, userNotify } from '../actions';
import { EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED } from '../actionTypes';

function* uploadExternalTrainingPlanProposal({ id, file, token }) {
  yield call(api.uploadExternalTrainingPlanProposal, id, file, token);
  yield put(externalSbatSaveSucceeded());
  yield put(userNotify('Training plan updated successfully'));
}

function* uploadExternalTrainingPlanProposalSaga() {
  yield takeLatest(
    EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
    uploadExternalTrainingPlanProposal
  );
}

export default uploadExternalTrainingPlanProposalSaga;
