import { useSelector } from 'react-redux';
import {
  ROLE_ID_NO_ROLE,
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_ENGAGEMENT,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from '../roles';

const getRolesUserCanView = (roles, user) => {
  return roles.filter((role) => {
    switch (user.role.id) {
      case ROLE_ID_NO_ROLE:
      case ROLE_ID_SCHOOL:
        return false;
      case ROLE_ID_SBAT_OFFICER:
        return [ROLE_ID_SCHOOL].includes(role.id);
      case ROLE_ID_ADMIN:
        return [
          ROLE_ID_NO_ROLE,
          ROLE_ID_SCHOOL,
          ROLE_ID_SBAT_ENGAGEMENT,
          ROLE_ID_SBAT_OFFICER,
        ].includes(role.id);
      case ROLE_ID_SYSTEM:
        return [
          ROLE_ID_NO_ROLE,
          ROLE_ID_SCHOOL,
          ROLE_ID_SBAT_ENGAGEMENT,
          ROLE_ID_SBAT_OFFICER,
          ROLE_ID_ADMIN,
        ].includes(role.id);
      default:
        return false;
    }
  });
};

const useRoles = () => {
  const authUser = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.apiConfig.config.user.roles);
  const rolesUserCanView = getRolesUserCanView(roles, authUser);

  return {
    roles,
    rolesUserCanView,
  };
};

export default useRoles;
