import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatNoteSaveSucceeded, userNotify } from '../actions';
import { SBAT_NOTE_SAVE_REQUESTED } from '../actionTypes';

function* saveSbatNote({ sbatId, data }) {
  yield call(api.saveSbatNote, sbatId, data);
  yield put(sbatNoteSaveSucceeded());
  yield put(userNotify('SBAT note saved successfully'));
}

function* saveSbatNoteSaga() {
  yield takeLatest(SBAT_NOTE_SAVE_REQUESTED, saveSbatNote);
}

export default saveSbatNoteSaga;
