import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ResourceTable from '../resourceTable/ResourceTable';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import {
  usersFetchRequested,
  updateUsersQuerySort,
  updateUsersQuerySearch,
  updateUsersQueryFilter,
  resetUsersQuery,
  authUserImpersonateRequested,
} from '../../redux/actions';
import config from '../../config';
import useRoles from '../../hooks/useRoles';
import useAuthUser from '../../hooks/useAuthUser';
import useSchools from '../../hooks/useSchools';
import useRtos from '../../hooks/useRtos';
import useUserStatuses from '../../hooks/useUserStatuses';
import {
  FILTER_TYPE_SELECT,
  FILTER_TYPE_SELECT_AUTOCOMPLETE,
  FILTER_TYPE_CHECKBOX,
} from '../resourceTable/filterTypes';
import { STATUS_ID_ACTIVE } from '../../statuses';
import { ROLE_ID_SCHOOL } from '../../roles';

const Users = () => {
  const { rolesUserCanView } = useRoles();
  const { schoolsUserCanView } = useSchools();
  const { rtos } = useRtos();
  const {
    authUser,
    authUserIsAdmin,
    authUserIsSystem,
    authUserIsOfficer,
  } = useAuthUser();
  const { userStatuses } = useUserStatuses();
  const dispatch = useDispatch();
  const impersonateUser = useCallback((user) => {
    dispatch(authUserImpersonateRequested(user));
  });

  return (
    <>
      <Breadcrumbs crumbs={[{ text: 'Users', key: 'users' }]} />
      <ResourceTable
        headers={[
          { id: 'username', label: 'Username', sortable: true },
          { id: 'first_name', label: 'First Name', sortable: true },
          { id: 'last_name', label: 'Last Name', sortable: true },
          {
            id: 'role_id',
            label: 'Role',
            sortable: true,
          },
          {
            id: 'schools.name',
            label: 'School',
            sortable: true,
          },
          {
            id: 'combined_rto.rto_name',
            label: 'TQI team',
            sortable: true,
          },
          {
            id: 'status_id',
            label: 'Status',
            sortable: true,
          },
          { id: 'actions', label: '', sortable: false },
        ]}
        filters={[
          !authUserIsOfficer && {
            id: 'role_id',
            label: 'Role',
            type: FILTER_TYPE_SELECT,
            options: rolesUserCanView.map((role) => {
              return {
                label: role.name,
                value: role.id,
              };
            }),
          },
          {
            id: 'school_id',
            label: 'School',
            type: FILTER_TYPE_SELECT_AUTOCOMPLETE,
            options: schoolsUserCanView.map((school) => {
              return {
                label: school.name,
                value: school.id,
              };
            }),
          },
          {
            id: 'rto_id',
            label: 'TQI team',
            type: FILTER_TYPE_SELECT,
            options: rtos.map((rto) => {
              return {
                label: rto.name,
                value: rto.id,
              };
            }),
          },
          {
            id: 'status_id',
            label: 'Status',
            type: FILTER_TYPE_SELECT,
            options: userStatuses.map((status) => {
              return {
                label: status.name,
                value: status.id,
              };
            }),
          },
          authUserIsOfficer && {
            id: 'my_assigned_schools',
            label: 'My Assigned Schools',
            type: FILTER_TYPE_CHECKBOX,
          },
        ].filter(Boolean)}
        mapResourceToColumns={(user) => {
          return [
            { label: user.username },
            { label: user.name.first },
            { label: user.name.last },
            { label: user.role.name },
            {
              label:
                user.assignedSchools.length > 0
                  ? user.assignedSchools
                      .reduce((acc, school) => {
                        acc.push(school.name);
                        return acc;
                      }, [])
                      .join(', ')
                  : user.school
                  ? user.school.name
                  : '',
            },
            { label: user.rto ? user.rto.name : '' },
            { label: user.status.name },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/users/${user.id}`,
                      color: 'secondary',
                      text: 'Manage User',
                      key: 'manage-user',
                    },
                    (authUserIsAdmin ||
                      authUserIsSystem ||
                      (authUserIsOfficer &&
                        user.role.id === ROLE_ID_SCHOOL &&
                        user.school &&
                        authUser.assignedSchools.find(
                          (school) => school.id === user.school.id
                        ))) &&
                      user.status.id === STATUS_ID_ACTIVE && {
                        onClick: () => impersonateUser(user),
                        color: 'secondary',
                        text: 'Impersonate User',
                        key: 'impersonate-user',
                      },
                  ].filter(Boolean)}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={usersFetchRequested}
        isFetchingResourceSelector={(state) => state.users.fetchingUsers}
        resourcesSelector={(state) => state.users.users}
        resourceQuerySelector={(state) => state.users.query}
        updateResourceQuerySort={updateUsersQuerySort}
        updateResourceQuerySearch={updateUsersQuerySearch}
        updateResourceQueryFilter={updateUsersQueryFilter}
        resetResourceQuery={resetUsersQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/users/export`}
        searchLabel="Search for users"
      />
    </>
  );
};

export default Users;
