import { all, put } from 'redux-saga/effects';
import fetchEmployers from './sagas/fetchEmployers';
import fetchEmployer from './sagas/fetchEmployer';
import saveEmployer from './sagas/saveEmployer';
import fetchAuthUser from './sagas/fetchAuthUser';
import notifyUser from './sagas/notifyUser';
import logoutAuthUser from './sagas/logoutAuthUser';
import loginSystemUser from './sagas/loginSystemUser';
import impersonateUser from './sagas/impersonateUser';
import fetchUsers from './sagas/fetchUsers';
import fetchUser from './sagas/fetchUser';
import fetchApiConfig from './sagas/fetchApiConfig';
import registerUser from './sagas/registerUser';
import saveUser from './sagas/saveUser';
import fetchPendingUsers from './sagas/fetchPendingUsers';
import fetchPendingSbats from './sagas/fetchPendingSbats';
import fetchSchools from './sagas/fetchSchools';
import fetchSchool from './sagas/fetchSchool';
import saveSchool from './sagas/saveSchool';
import fetchStudents from './sagas/fetchStudents';
import fetchStudent from './sagas/fetchStudent';
import saveStudent from './sagas/saveStudent';
import fetchContacts from './sagas/fetchContacts';
import fetchContact from './sagas/fetchContact';
import saveContact from './sagas/saveContact';
import fetchSbats from './sagas/fetchSbats';
import fetchSbat from './sagas/fetchSbat';
import saveSbat from './sagas/saveSbat';
import fetchSbatActivities from './sagas/fetchSbatActivities';
import fetchSbatNotes from './sagas/fetchSbatNotes';
import saveSbatNote from './sagas/saveSbatNote';
import fetchExternalSbat from './sagas/fetchExternalSbat';
import saveExternalSbat from './sagas/saveExternalSbat';
import saveAuthUserProfile from './sagas/saveAuthUserProfile';
import fetchTrainingPlanOptions from './sagas/fetchTrainingPlanOptions';
import redirectToUsersWithQuery from './sagas/redirectToUsersWithQuery';
import redirectToSbatsWithQuery from './sagas/redirectToSbatsWithQuery';
import uploadTrainingPlan from './sagas/uploadTrainingPlan';
import uploadTrainingPlanProposal from './sagas/uploadTrainingPlanProposal';
import uploadExternalTrainingPlan from './sagas/uploadExternalTrainingPlan';
import uploadExternalTrainingPlanProposal from './sagas/uploadExternalTrainingPlanProposal';
import { unexpectedErrorOccurred, authUserLogoutSucceeded } from './actions';
import fetchQualifications from './sagas/fetchQualifications';
import fetchQualification from './sagas/fetchQualification';
import saveQualification from './sagas/saveQualification';

function* withErrorHandler(fn) {
  try {
    yield fn;
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(authUserLogoutSucceeded());
      return;
    }

    if (e.response) {
      console.log(e.response);
    }
    console.log(e);
    yield put(unexpectedErrorOccurred());
  }
}

export default function* rootSaga() {
  yield all([
    withErrorHandler(fetchEmployers()),
    withErrorHandler(fetchEmployer()),
    withErrorHandler(saveEmployer()),
    withErrorHandler(fetchSchools()),
    withErrorHandler(fetchSchool()),
    withErrorHandler(saveSchool()),
    withErrorHandler(fetchContacts()),
    withErrorHandler(fetchContact()),
    withErrorHandler(saveContact()),
    withErrorHandler(fetchSbats()),
    withErrorHandler(fetchSbat()),
    withErrorHandler(saveSbat()),
    withErrorHandler(fetchSbatActivities()),
    withErrorHandler(fetchSbatNotes()),
    withErrorHandler(saveSbatNote()),
    withErrorHandler(fetchExternalSbat()),
    withErrorHandler(saveExternalSbat()),
    withErrorHandler(fetchAuthUser()),
    withErrorHandler(impersonateUser()),
    withErrorHandler(notifyUser()),
    withErrorHandler(logoutAuthUser()),
    withErrorHandler(loginSystemUser()),
    withErrorHandler(fetchUsers()),
    withErrorHandler(fetchUser()),
    withErrorHandler(fetchApiConfig()),
    withErrorHandler(registerUser()),
    withErrorHandler(saveUser()),
    withErrorHandler(fetchTrainingPlanOptions()),
    withErrorHandler(fetchPendingUsers()),
    withErrorHandler(redirectToUsersWithQuery()),
    withErrorHandler(redirectToSbatsWithQuery()),
    withErrorHandler(saveAuthUserProfile()),
    withErrorHandler(fetchPendingSbats()),
    withErrorHandler(uploadTrainingPlan()),
    withErrorHandler(uploadTrainingPlanProposal()),
    withErrorHandler(uploadExternalTrainingPlan()),
    withErrorHandler(uploadExternalTrainingPlanProposal()),
    withErrorHandler(fetchStudents()),
    withErrorHandler(fetchStudent()),
    withErrorHandler(saveStudent()),
    withErrorHandler(fetchQualifications()),
    withErrorHandler(fetchQualification()),
    withErrorHandler(saveQualification()),
  ]);
}
