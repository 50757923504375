import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  required: {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: theme.palette.required.border,
      },
    },
  },
}));

const TextInput = ({
  defaultValue,
  control,
  hasError,
  errorText,
  name,
  label,
  rules = {},
  disabled = false,
  requiredOnSubmit = false,
  multiline = false,
  rows = 1,
}) => {
  const classes = useStyles();

  return (
    <Controller
      as={
        <TextField
          classes={{ root: requiredOnSubmit ? classes.required : classes.root }}
          variant="outlined"
          margin="normal"
          fullWidth
          id={name}
        />
      }
      label={label}
      name={name}
      defaultValue={defaultValue || ''}
      control={control}
      error={hasError}
      helperText={errorText}
      rules={rules}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
    />
  );
};

export default TextInput;
