export const SBATS_FETCH_REQUESTED = 'SBATS_FETCH_REQUESTED';
export const SBATS_FETCH_SUCCEEDED = 'SBATS_FETCH_SUCCEEDED';
export const UPDATE_SBATS_QUERY_SORT = 'UPDATE_SBATS_QUERY_SORT';
export const UPDATE_SBATS_QUERY_SEARCH = 'UPDATE_SBATS_QUERY_SEARCH';
export const UPDATE_SBATS_QUERY_FILTER = 'UPDATE_SBATS_QUERY_FILTER';
export const RESET_SBATS_QUERY = 'RESET_SBATS_QUERY';

export const SBAT_FETCH_REQUESTED = 'SBAT_FETCH_REQUESTED';
export const SBAT_FETCH_SUCCEEDED = 'SBAT_FETCH_SUCCEEDED';
export const SBAT_NOT_FOUND = 'SBAT_NOT_FOUND';

export const SBAT_ACTIVITIES_FETCH_REQUESTED =
  'SBAT_ACTIVITIES_FETCH_REQUESTED';
export const SBAT_ACTIVITIES_FETCH_SUCCEEDED =
  'SBAT_ACTIVITIES_FETCH_SUCCEEDED';

export const SBAT_SAVE_REQUESTED = 'SBAT_SAVE_REQUESTED';
export const SBAT_SAVE_SUCCEEDED = 'SBAT_SAVE_SUCCEEDED';

export const SBAT_NOTES_FETCH_REQUESTED = 'SBAT_NOTES_FETCH_REQUESTED';
export const SBAT_NOTES_FETCH_SUCCEEDED = 'SBAT_NOTES_FETCH_SUCCEEDED';
export const SBAT_NOTE_SAVE_REQUESTED = 'SBAT_NOTE_SAVE_REQUESTED';
export const SBAT_NOTE_SAVE_SUCCEEDED = 'SBAT_NOTE_SAVE_SUCCEEDED';

export const PENDING_SBATS_FETCH_REQUESTED = 'PENDING_SBATS_FETCH_REQUESTED';
export const PENDING_SBATS_FETCH_SUCCEEDED = 'PENDING_SBATS_FETCH_SUCCEEDED';
export const REDIRECT_TO_SBATS_WITH_QUERY = 'REDIRECT_TO_SBATS_WITH_QUERY';
export const SET_SBATS_QUERY = 'SET_SBATS_QUERY';

export const TRAINING_PLAN_OPTIONS_FETCH_REQUESTED =
  'TRAINING_PLAN_OPTIONS_FETCH_REQUESTED';

export const TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED =
  'TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED';

export const UPLOAD_TRAINING_PLAN_REQUESTED = 'UPLOAD_TRAINING_PLAN_REQUESTED';

export const UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED =
  'UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED';
