import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import AutoCompleteInputAsync from '../form/AutoCompleteInputAsync';
import api from '../../api';

const SelectQualification = ({
  initialQualification,
  watch,
  setValue,
  control,
  errors,
  disabled,
}) => {
  const selectedQualification = watch('qualification');

  useEffect(() => {
    if (selectedQualification && selectedQualification.data) {
      setValue('qualification', selectedQualification);
    }
  }, [selectedQualification, initialQualification]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        <AutoCompleteInputAsync
          label="Search qualifications"
          key="qualification"
          name="qualification"
          control={control}
          hasError={Boolean(errors.qualification)}
          errorText={errors.qualification && 'Required'}
          onSuggest={api.suggestQualificationsIvet}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default SelectQualification;
