import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import AutoCompleteInput from '../form/AutoCompleteInput';
import AutoCompleteInputAsync from '../form/AutoCompleteInputAsync';
import useSchools from '../../hooks/useSchools';
import api from '../../api';

const SelectStudent = ({
  initialStudent,
  watch,
  setValue,
  control,
  errors,
  disabled,
}) => {
  const [students, setStudents] = useState([]);

  const { schoolsUserCanView } = useSchools();

  const defaultSchool = initialStudent
    ? schoolsUserCanView.find((school) => {
        return (
          school.id ===
          (initialStudent.school ? initialStudent.school.id : null)
        );
      })
    : (schoolsUserCanView &&
        schoolsUserCanView.length === 1 &&
        schoolsUserCanView[0]) ||
      null;

  const selectedSchool = watch('school'); // || defaultSchool;

  const suggestStudents = (q) => api.suggestStudents(selectedSchool.id, q);

  useEffect(() => {
    // delete student selection if school is changed
    setValue('student', null);
    if (selectedSchool) {
      (async () => {
        const data = await suggestStudents('');
        setStudents(data);
      })();
    } else {
      setStudents([]);
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (initialStudent && students) {
      // set initial student
      const defaultStudent = students.find((student) => {
        return student.id === initialStudent.id;
      });
      if (
        !defaultStudent &&
        selectedSchool &&
        selectedSchool.id === initialStudent.school.id
      ) {
        // student not within the abbreviated student list
        // prepend initial student to students list
        setStudents([
          { id: initialStudent.id, name: initialStudent.full_name },
          ...students,
        ]);
      }
      if (defaultStudent) {
        setValue('student', defaultStudent);
      }
    }
  }, [initialStudent, students]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        <AutoCompleteInput
          options={schoolsUserCanView}
          label="Select a school"
          key="school"
          name="school"
          control={control}
          defaultValue={defaultSchool}
          disabled={disabled || schoolsUserCanView.length === 1}
          hasError={Boolean(errors.school)}
          errorText={errors.school && 'Required'}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <AutoCompleteInputAsync
          label="Select a student"
          key="student"
          name="student"
          control={control}
          hasError={Boolean(errors.student)}
          errorText={errors.student && 'Required'}
          onSuggest={suggestStudents}
          disabled={disabled || !selectedSchool}
          defaultOptions={students}
        />
      </Grid>
    </Grid>
  );
};

export default SelectStudent;
