import {
  ROLE_ID_NO_ROLE,
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_SBAT_ENGAGEMENT,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from './roles';

const abilities = {
  users: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN, ROLE_ID_SBAT_OFFICER].includes(
        user.role.id
      );
    },
    viewSelf: (user) => {
      if (!user) {
        return false;
      }

      return true;
    },
    registerSelf: (user) => {
      if (!user) {
        return false;
      }

      return user.role.id === ROLE_ID_NO_ROLE;
    },
    viewPending: (user) => {
      if (!user) {
        return false;
      }

      return true;
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN, ROLE_ID_SBAT_OFFICER].includes(
        user.role.id
      );
    },
  },
  sbats: {
    viewPending: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SCHOOL, ROLE_ID_SBAT_OFFICER].includes(user.role.id);
    },
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
    add: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
  },
  employers: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return true;
    },
    add: (user) => {
      if (!user) {
        return false;
      }

      return true;
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return true;
    },
  },
  contacts: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN, ROLE_ID_SBAT_OFFICER].includes(
        user.role.id
      );
    },
    edit: (user) => {
      if (!user) {
        return false;
      }
      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN, ROLE_ID_SBAT_OFFICER].includes(
        user.role.id
      );
    },
  },
  schools: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN, ROLE_ID_SBAT_OFFICER].includes(
        user.role.id
      );
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN].includes(user.role.id);
    },
  },
  students: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
    add: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return [
        ROLE_ID_SYSTEM,
        ROLE_ID_ADMIN,
        ROLE_ID_SBAT_OFFICER,
        ROLE_ID_SBAT_ENGAGEMENT,
        ROLE_ID_SCHOOL,
      ].includes(user.role.id);
    },
  },
  qualifications: {
    viewAny: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN].includes(user.role.id);
    },
    add: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN].includes(user.role.id);
    },
    edit: (user) => {
      if (!user) {
        return false;
      }

      return [ROLE_ID_SYSTEM, ROLE_ID_ADMIN].includes(user.role.id);
    },
  },
};

export default abilities;
