import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import grey from '@material-ui/core/colors/grey';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SBAT_ACTION_SUBMIT } from '../../../sbatActions';
import TrainingPlanProgress from './TrainingPlanProgress';
import {
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
  SBAT_STATUS_ID_PENDING_SBAT_GENERATE_TPP,
  getSbatStatusIndex,
} from '../../../sbatStatuses';
import SelectBooleanInput from '../../form/SelectBooleanInput';
import TextInput from '../../form/TextInput';
import useAuthUser from '../../../hooks/useAuthUser';
import { integerValidation, textValidation } from '../../form/ValidationSchema';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileUpload: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed',
    borderColor: grey[400],
    color: grey[400],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  uploadText: {
    color: grey[500],
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: grey[600],
  },
  trainingPlanBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  noTrainingPlanBox: {
    color: theme.palette.error.main,
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
  uploadedFileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  downloadTrainingPlan: {
    textDecoration: 'none',
  },
  uploadError: {
    color: theme.palette.error.main,
  },
  progressBox: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadBox: {
    marginBottom: theme.spacing(3),
  },
}));

const TrainingPlanUpload = ({
  sbat,
  title = 'Training Plan',
  trainingPlanDownloadUrl = null,
  uploadTrainingPlanRequestedAction,
  token = null,
  sbatSaveRequestedAction,
}) => {
  const { authUserHasRole } = useAuthUser();

  const classes = useStyles();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxSize: 10000000, // 10 MB
    accept: ['application/pdf'],
  });
  const { control, handleSubmit, errors, register } = useForm({
    validationSchema:
      sbat.status.id === SBAT_STATUS_ID_PENDING_RTO_REVIEW
        ? yup.object().shape({
            r1_confirm: integerValidation
              .getValidationSchema()
              .required('Required'),
            r1_confirm_text: textValidation
              .getValidationSchema()
              .required('Required'),
          })
        : null,
    reValidateMode: 'onSubmit',
  });

  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const file = acceptedFiles[0] ? acceptedFiles[0] : null;
  const fileRejection = fileRejections[0] ? fileRejections[0] : null;

  const uploadTrainingPlan = () => {
    dispatch(
      uploadTrainingPlanRequestedAction({
        id: sbat.id,
        file,
        token,
      })
    );
  };

  const onSubmit = (data) => {
    dispatch(
      sbatSaveRequestedAction({
        id: sbat.id,
        action: SBAT_ACTION_SUBMIT,
        data: data,
        token,
      })
    );
  };

  return (
    <Box>
      <Box className={classes.trainingPlanBox}>
        <Box className={classes.title}>{title}</Box>
        {trainingPlanDownloadUrl ? (
          <a
            href={trainingPlanDownloadUrl}
            className={classes.downloadTrainingPlan}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary">
              <CloudDownloadIcon className={classes.downloadIcon} /> Download
            </Button>
          </a>
        ) : (
          <Box className={classes.noTrainingPlanBox}>
            This SBAT has no training plan
          </Box>
        )}
      </Box>

      <Box className={classes.uploadBox}>
        <Box>
          <Box className={classes.title}>Upload New Version</Box>
          <div
            {...getRootProps({
              className: classes.fileUpload,
            })}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon fontSize="large" />
            <div className={classes.uploadText}>
              Drop a file here, or click to browse
            </div>
          </div>
        </Box>
        <Box className={classes.uploadedFileBox}>
          <Box>
            {file && <Box>{file.name}</Box>}
            {fileRejection && (
              <Box className={classes.uploadError}>
                {fileRejection.errors[0].message}
              </Box>
            )}
          </Box>
          <Box>
            <Button
              disabled={!file}
              variant="contained"
              color="secondary"
              onClick={(e) => uploadTrainingPlan()}
            >
              Update {title}
            </Button>
          </Box>
        </Box>
      </Box>
      {sbat.can_progress &&
        (sbat.status.id === SBAT_STATUS_ID_PENDING_RTO_REVIEW ? (
          <Box>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={5}>
                <Grid item xs={12} lg={3}>
                  Please confirm ability to deliver training:
                  <SelectBooleanInput
                    name="r1_confirm"
                    label="Confirm"
                    control={control}
                    defaultValue={sbat.r1_confirm}
                    hasError={Boolean(errors.r1_confirm)}
                    errorText={errors?.r1_confirm?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={9}>
                  If Yes, provide delivery information. E.g. location, timing,
                  mode of delivery etc. <br />
                  If No, provide reason for declining.
                  <TextInput
                    name="r1_confirm_text"
                    label="Text"
                    multiline
                    rows={5}
                    defaultValue={sbat.r1_confirm_text}
                    control={control}
                    hasError={Boolean(errors.r1_confirm_text)}
                    errorText={errors?.r1_confirm_text?.message}
                  />
                </Grid>
              </Grid>
              <Box className={classes.progressBox}>
                <TrainingPlanProgress
                  savingSbat={savingSbat}
                  control={control}
                  register={register}
                  sbat={sbat}
                  onProgress={handleSubmit(onSubmit)}
                />
              </Box>
            </form>
          </Box>
        ) : authUserHasRole &&
          getSbatStatusIndex(sbat.status.id) >
            getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW) &&
          sbat.status.id !== SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW ? (
          <Box>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={3}>
                Please confirm ability to deliver training:
                <SelectBooleanInput
                  name="r1_confirm"
                  label="Confirm"
                  control={control}
                  defaultValue={sbat.r1_confirm}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} lg={9}>
                If Yes, provide delivery information. E.g. location, timing,
                mode of delivery etc. <br />
                If No, provide reason for declining.
                <TextInput
                  name="r1_confirm_text"
                  label="Text"
                  multiline
                  rows={5}
                  defaultValue={sbat.r1_confirm_text}
                  control={control}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Box className={classes.progressBox}>
              <TrainingPlanProgress
                savingSbat={savingSbat}
                control={control}
                register={register}
                sbat={sbat}
                onProgress={handleSubmit(onSubmit)}
              />
            </Box>
          </Box>
        ) : getSbatStatusIndex(sbat.status.id) >
            getSbatStatusIndex(SBAT_STATUS_ID_PENDING_SBAT_GENERATE_TPP) &&
          sbat.status.id !== SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW ? (
          <Box className={classes.progressBox}>
            <TrainingPlanProgress
              savingSbat={savingSbat}
              control={control}
              register={register}
              sbat={sbat}
              onProgress={handleSubmit(onSubmit)}
            />
          </Box>
        ) : (
          <></>
        ))}
    </Box>
  );
};

export default TrainingPlanUpload;
