import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';

import {
  externalSbatFetchRequested,
  externalUploadTrainingPlanRequested,
  externalUploadTrainingPlanProposalRequested,
  externalSbatSaveRequested,
} from '../../../redux/actions';
import Error404 from '../../errors/Error404';
import TabSections from '../../TabSections';
import SbatDetails from '../SbatDetails';
import TrainingPlanForm from './TrainingPlanForm';
import TrainingPlanUpload from '../trainingPlan/TrainingPlanUpload';
import SbatProgressed from './SbatProgressed';
import {
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  SBAT_STATUS_ID_PENDING_RTO_SUBMISSION,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
  getSbatStatusIndex,
} from '../../../sbatStatuses';
import config from '../../../config';

const getTrainingPlanUrl = (sbat, token) => {
  if (!sbat || !sbat.training_plan_file) {
    return null;
  }

  return `${config.apiBaseUrl}/external/sbats/file/${sbat.training_plan_file.id}?token=${token}`;
};
const getTrainingPlanProposalUrl = (sbat, token) => {
  if (!sbat || !sbat.training_plan_proposal_file) {
    return null;
  }

  return `${config.apiBaseUrl}/external/sbats/file/${sbat.training_plan_proposal_file.id}?token=${token}`;
};
const Sbat = () => {
  const dispatch = useDispatch();
  const sbat = useSelector((state) => state.external.sbat);
  const fetchingSbat = useSelector((state) => state.external.fetchingSbat);
  const sbatNotFound = useSelector((state) => state.external.sbatNotFound);

  const location = useLocation();
  const token = location.hash && location.hash.substring(1);
  const { id } = useParams();

  useEffect(() => {
    if (
      !fetchingSbat &&
      !sbatNotFound &&
      (!sbat || sbat.id.toString() !== id)
    ) {
      dispatch(externalSbatFetchRequested(id, token));
    }
  }, [dispatch, id, token, sbat, fetchingSbat, sbatNotFound]);

  const trainingPlanDownloadUrl = getTrainingPlanUrl(sbat, token);
  const trainingPlanProposalDownloadUrl = getTrainingPlanProposalUrl(
    sbat,
    token
  );

  return sbatNotFound ? (
    <Error404 />
  ) : (
    sbat && (
      <>
        {sbat.can_progress ? (
          <TabSections
            sections={[
              {
                label: 'Details',
                icon: <InfoIcon />,
                contents: <SbatDetails sbat={sbat} />,
              },
              {
                label: 'Training Plan Proposal',
                selected:
                  getSbatStatusIndex(sbat.status.id) <
                  getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_SUBMISSION),
                icon: <AssignmentIcon />,
                contents:
                  getSbatStatusIndex(sbat.status.id) <
                  getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW) ? (
                    <TrainingPlanForm initialSbat={sbat} token={token} />
                  ) : (
                    <TrainingPlanUpload
                      title="Training Plan Proposal"
                      trainingPlanDownloadUrl={trainingPlanProposalDownloadUrl}
                      uploadTrainingPlanRequestedAction={
                        externalUploadTrainingPlanProposalRequested
                      }
                      sbatSaveRequestedAction={externalSbatSaveRequested}
                      token={token}
                      sbat={sbat}
                    />
                  ),
              },
              getSbatStatusIndex(sbat.status.id) >
                getSbatStatusIndex(
                  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW
                ) && {
                label: 'Training Plan',
                selected:
                  getSbatStatusIndex(sbat.status.id) >=
                  getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_SUBMISSION),
                icon: <AssignmentIcon />,
                contents:
                  getSbatStatusIndex(sbat.status.id) <
                  getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW) ? (
                    <TrainingPlanForm initialSbat={sbat} token={token} />
                  ) : (
                    <TrainingPlanUpload
                      trainingPlanDownloadUrl={trainingPlanDownloadUrl}
                      uploadTrainingPlanRequestedAction={
                        externalUploadTrainingPlanRequested
                      }
                      sbatSaveRequestedAction={externalSbatSaveRequested}
                      token={token}
                      sbat={sbat}
                    />
                  ),
              },
            ]}
          />
        ) : (
          <SbatProgressed />
        )}
      </>
    )
  );
};

export default Sbat;
