import {
  AUTH_USER_FETCH_REQUESTED,
  AUTH_USER_FETCH_FAILED,
  AUTH_USER_FETCH_SUCCEEDED,
  AUTH_USER_LOGOUT_REQUESTED,
  AUTH_USER_LOGOUT_SUCCEEDED,
  SYSTEM_USER_LOGIN_REQUESTED,
  SYSTEM_USER_LOGIN_FAILED,
  SYSTEM_USER_LOGIN_SUCCEEDED,
  AUTH_USER_REGISTER_REQUESTED,
  AUTH_USER_REGISTER_SUCCEEDED,
  AUTH_USER_PROFILE_SAVE_REQUESTED,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  AUTH_USER_IMPERSONATE_REQUESTED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from './actionTypes';

export const authUserFetchRequested = () => ({
  type: AUTH_USER_FETCH_REQUESTED,
});

export const authUserFetchFailed = () => ({
  type: AUTH_USER_FETCH_FAILED,
});

export const authUserFetchSucceeded = (user) => ({
  type: AUTH_USER_FETCH_SUCCEEDED,
  user,
});

export const authUserLogoutRequested = () => ({
  type: AUTH_USER_LOGOUT_REQUESTED,
});

export const authUserLogoutSucceeded = () => ({
  type: AUTH_USER_LOGOUT_SUCCEEDED,
});

export const systemUserLoginRequested = (username, password) => ({
  type: SYSTEM_USER_LOGIN_REQUESTED,
  username,
  password,
});

export const systemUserLoginSucceeded = (user) => ({
  type: SYSTEM_USER_LOGIN_SUCCEEDED,
  user,
});

export const systemUserLoginFailed = () => ({
  type: SYSTEM_USER_LOGIN_FAILED,
});

export const authUserRegisterRequested = (data) => ({
  type: AUTH_USER_REGISTER_REQUESTED,
  data,
});

export const authUserRegisterSucceeded = (user) => ({
  type: AUTH_USER_REGISTER_SUCCEEDED,
  user,
});

export const authUserProfileSaveRequested = (data) => ({
  type: AUTH_USER_PROFILE_SAVE_REQUESTED,
  data,
});

export const authUserProfileSaveSucceeded = (user) => ({
  type: AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  user,
});

export const authUserImpersonateRequested = (user) => ({
  type: AUTH_USER_IMPERSONATE_REQUESTED,
  user,
});

export const authUserImpersonateSucceeded = (user) => ({
  type: AUTH_USER_IMPERSONATE_SUCCEEDED,
  user,
});
