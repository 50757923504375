import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../form/TextInput';
import DateInput from '../../../form/DateInput';
import CheckboxInput from '../../../form/CheckboxInput';
import TrainingPlanSection from '../TrainingPlanSection';
import AutoCompleteInputAsync from '../../../form/AutoCompleteInputAsync';
import api from '../../../../api';

const Rto1Details = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  enabled,
  requiredOnSubmit,
}) => {
  const selectedContact = watch('r1_contact_email');

  useEffect(() => {
    if (
      selectedContact &&
      selectedContact.data &&
      (!initialSbat.r1_contact_email ||
        initialSbat.r1_contact_email !== selectedContact.data.email)
    ) {
      setValue([
        { r1_contact_name: selectedContact.data.name || '' },
        { r1_contact_phone: selectedContact.data.phone || '' },
        { r1_contact_fax: selectedContact.data.fax || '' },
        { r1_contact_mobile: selectedContact.data.mobile || '' },
      ]);
    }
  }, [selectedContact, initialSbat]);

  return (
    <>
      <TrainingPlanSection title="Registered Training Organisation (RTO) 1">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2}>
            <CheckboxInput
              register={register}
              name="r1_svet"
              label="SVET"
              defaultValue={initialSbat.r1_svet}
              disabled={!enabled}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <DateInput
              label="Start Date"
              name="r1_start_date"
              defaultValue={initialSbat.r1_start_date || ''}
              control={control}
              hasError={Boolean(errors.r1_start_date)}
              errorText={errors?.r1_start_date?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_start_date}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <DateInput
              label="Estimated End Date"
              name="r1_end_date"
              defaultValue={initialSbat.r1_end_date || ''}
              control={control}
              hasError={Boolean(errors.r1_end_date)}
              errorText={errors?.r1_end_date?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_end_date}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              label="RTO Legal Name"
              name="r1_name"
              defaultValue={initialSbat.r1_name || ''}
              control={control}
              hasError={Boolean(errors.r1_name)}
              errorText={errors?.r1_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              label="RTO Trading Name"
              name="r1_trading_name"
              defaultValue={initialSbat.r1_trading_name || ''}
              control={control}
              hasError={Boolean(errors.r1_trading_name)}
              errorText={errors?.r1_trading_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_trading_name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              label="ABN"
              name="r1_abn"
              defaultValue={initialSbat.r1_abn || ''}
              control={control}
              hasError={Boolean(errors.r1_abn)}
              errorText={errors?.r1_abn?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_abn}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <AutoCompleteInputAsync
              label="Contact Email"
              key="r1_contact_email"
              name="r1_contact_email"
              defaultValue={initialSbat.r1_contact_email || ''}
              control={control}
              hasError={Boolean(errors.r1_contact_email)}
              errorText={errors?.r1_contact_email?.message}
              freeSolo
              autoSelect
              onSuggest={api.suggestContacts}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_contact_email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="r1_contact_name"
              label="Contact Name"
              defaultValue={initialSbat.r1_contact_name || ''}
              control={control}
              hasError={Boolean(errors.r1_contact_name)}
              errorText={errors?.r1_contact_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_contact_name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="r1_contact_phone"
              label="Contact Phone"
              defaultValue={initialSbat.r1_contact_phone || ''}
              control={control}
              hasError={Boolean(
                errors.r1_contact_phone || errors.r1_contact_phone_or_mobile
              )}
              errorText={
                errors?.r1_contact_phone?.message ??
                errors?.r1_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="r1_contact_fax"
              label="Contact Fax"
              defaultValue={initialSbat.r1_contact_fax || ''}
              control={control}
              hasError={Boolean(errors.r1_contact_fax)}
              errorText={errors?.r1_contact_fax?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_contact_fax}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="r1_contact_mobile"
              label="Contact Mobile"
              defaultValue={initialSbat.r1_contact_mobile || ''}
              control={control}
              hasError={Boolean(
                errors.r1_contact_mobile || errors.r1_contact_phone_or_mobile
              )}
              errorText={
                errors?.r1_contact_mobile?.message ??
                errors?.r1_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_contact_phone_or_mobile}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="RTO National Code"
              name="r1_national_code"
              defaultValue={initialSbat.r1_national_code || ''}
              control={control}
              hasError={Boolean(errors.r1_national_code)}
              errorText={errors?.r1_national_code?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.r1_national_code}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default Rto1Details;
