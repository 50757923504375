import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxInput = ({
  register,
  defaultValue,
  name,
  label,
  disabled = false,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          inputRef={register}
          defaultChecked={!!defaultValue}
          name={name}
          color="secondary"
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default CheckboxInput;
