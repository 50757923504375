import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import blueGrey from '@material-ui/core/colors/blueGrey';

import {
  ROLE_ID_NO_ROLE,
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_ENGAGEMENT,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from './roles';

const sbatColors = {
  primary: {
    darkBlue: '#041e42',
    lightBlue: '#C8DCF0',
    red: '#CE0037',
    white: '#FFFFFF',
    black: '#000000',
  },
  secondary: {
    darkBlue: '#1D428A',
    midBlue: '#407EC9',
    lightBlue: '#6CACE4',
    darkRed: '#EE3C48',
    darkerRed: '#5e171c',
    lightRed: '#F3B8B5',
    gray: '#E5E5E5',
  },
};

const sbatRoleColors = {
  [ROLE_ID_NO_ROLE]: grey[500],
  [ROLE_ID_SCHOOL]: lightBlue[500],
  [ROLE_ID_SBAT_ENGAGEMENT]: blue[500],
  [ROLE_ID_SBAT_OFFICER]: red[500],
  [ROLE_ID_ADMIN]: purple[500],
  [ROLE_ID_SYSTEM]: blueGrey[500],
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: sbatColors.primary.darkBlue,
    },
    secondary: {
      main: sbatColors.primary.red,
    },
    error: {
      main: sbatColors.secondary.darkRed,
    },
    required: {
      border: sbatColors.secondary.darkerRed,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 75,
    },
  },
  sbatColors,
  sbatRoleColors,
});

export default theme;
