import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import useSbatStatuses from '../../hooks/useSbatStatuses';
import {
  SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION,
  SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW,
  SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS,
  SBAT_STATUS_ID_PENDING_SBAT_GENERATE_TPP,
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
  SBAT_STATUS_ID_PENDING_RTO_SUBMISSION,
  SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION,
  SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE,
} from '../../sbatStatuses';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  progressCard: {
    height: '100%',
  },
  waitingBox: {
    display: 'flex',
    alightItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  progressBox: {
    marginBottom: theme.spacing(1),
  },
  stepper: {
    height: '100%',
  },
}));

const SbatProgress = ({ sbat }) => {
  const classes = useStyles();
  const { sbatStatuses } = useSbatStatuses();

  const statusIndex = sbatStatuses.findIndex((status) => {
    return status.id === sbat.status.id;
  });

  const stepDescription = {
    [SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION]:
      'School to submit initial detail to establish SBAT, requiring student (details filled out and linked to a school) + Employer to start',
    [SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW]:
      'SBAT officer to review initial information, establish contact with Employer and determining ACAP, RTO details. Draft TPP will be generated when progress from this step.',
    [SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS]:
      'SBAT Officer can establish confirmation/verification process with Employer detail. Draft TPP will be generated when progress from this step.',
    [SBAT_STATUS_ID_PENDING_SBAT_GENERATE_TPP]:
      'SBAT Officer to email Training Plan Proposal (TPP) workflow to RTO (optional to ACAP)',
    [SBAT_STATUS_ID_PENDING_RTO_REVIEW]:
      'RTO to confirm availability of training, progress by RTO response directly or SBAT officer to update',
    [SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW]:
      'SBAT officer to review RTO response and progress to ACAP for confirmation of signup arrangement',
    [SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION]:
      'SBAT officer to confirm TPP process is complete',
    [SBAT_STATUS_ID_PENDING_RTO_SUBMISSION]:
      'SBAT officer to review draft RTO full training plan, critical to check NESA requirement. SBAT officer responsible for full sign up if SVET',
    [SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION]:
      'SBAT officer to review return completed training plan',
    [SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE]:
      'SBAT officer to check IVETS dataset prior marking complete',
  }[sbat.status.id];

  const stepWaitingOnText = {
    [SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION]: 'Waiting on school',
    [SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW]: 'Waiting on SBAT officer',
    [SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS]: 'Waiting on employer',
    [SBAT_STATUS_ID_PENDING_SBAT_GENERATE_TPP]: 'Waiting on SBAT officer',
    [SBAT_STATUS_ID_PENDING_RTO_REVIEW]: 'Waiting on RTO',
    [SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW]: 'Waiting on SBAT officer',
    [SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION]: 'Waiting on ACAP',
    [SBAT_STATUS_ID_PENDING_RTO_SUBMISSION]: 'Waiting on RTO',
    [SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION]: 'Waiting on SBAT officer',
    [SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE]: 'Complete',
  }[sbat.status.id];

  return (
    <>
      <Grid className={classes.root} container spacing={1}>
        <Grid item lg={3}>
          <Card className={classes.progressCard}>
            <CardContent>
              <Box className={classes.progressBox}>
                {sbat.waiting_on_my_progression ? (
                  <Typography color="error">
                    <Box className={classes.waitingBox}>
                      <InfoIcon className={classes.icon} />
                      <strong>Waiting on your progression</strong>
                    </Box>
                  </Typography>
                ) : (
                  <Typography color="textSecondary">
                    <strong>{stepWaitingOnText}</strong>
                  </Typography>
                )}
              </Box>
              <Typography variant="body2" component="p">
                {stepDescription}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={9}>
          <Box className={classes.stepper}>
            <Stepper
              classes={{ root: classes.stepper }}
              elevation={2}
              activeStep={statusIndex}
              alternativeLabel
            >
              {sbatStatuses.map((status, index) => (
                <Step key={status.id}>
                  <StepLabel>
                    {index === statusIndex ? (
                      <strong>{status.name}</strong>
                    ) : (
                      <>{status.name}</>
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SbatProgress;
