import React from 'react';

import DetailsList from '../DetailsList';

const UserDetails = ({ user }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Name',
          text: `${user.name.first} ${user.name.last}`,
        },
        {
          label: 'Username',
          text: user.username,
        },
        {
          label: 'Role',
          text: user.role.name,
        },
        user.school && {
          label: 'School',
          text: user.school.name,
        },
        user.rto && {
          label: 'TQI team',
          text: user.rto.name,
        },
        user.assignedSchools?.length > 0 && {
          label: 'Assigned schools',
          text: user.assignedSchools
            .reduce((acc, school) => {
              acc.push(school.name);
              return acc;
            }, [])
            .join(', '),
        },
        user.assignedEmployers?.length > 0 && {
          label: 'Assigned employers',
          text: user.assignedEmployers
            .reduce((acc, employer) => {
              acc.push(employer.name);
              return acc;
            }, [])
            .join(', '),
        },
        {
          label: 'Status',
          text: user.status.name,
        },
      ].filter(Boolean)}
    />
  );
};

export default UserDetails;
