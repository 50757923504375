import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../../api';
import { authUserRegisterSucceeded, userNotify } from '../actions';
import { AUTH_USER_REGISTER_REQUESTED } from '../actionTypes';

function* registerUser(action) {
  const user = yield call(api.registerUser, action.data);
  // update state and redirect
  yield put(authUserRegisterSucceeded(user));
  yield put(userNotify('Registered successfully'));
  yield put(push('/pending'));
}

function* registerUserSaga() {
  yield takeLatest(AUTH_USER_REGISTER_REQUESTED, registerUser);
}

export default registerUserSaga;
