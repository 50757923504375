import { useSelector } from 'react-redux';
import { STATUS_ID_ACTIVE, STATUS_ID_PENDING } from '../statuses';
import {
  ROLE_ID_NO_ROLE,
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_ENGAGEMENT,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from '../roles';

const useAuthUser = () => {
  const authUser = useSelector((state) => state.auth.user);
  const authUserIsAuthenticated = authUser !== null;
  const authUserHasNoRole =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_NO_ROLE;
  const authUserHasRole =
    authUserIsAuthenticated && authUser.role.id !== ROLE_ID_NO_ROLE;
  const authUserIsSchool =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_SCHOOL;
  const authUserIsEngagement =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_SBAT_ENGAGEMENT;
  const authUserIsOfficer =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_SBAT_OFFICER;
  const authUserIsAdmin =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_ADMIN;
  const authUserIsSystem =
    authUserIsAuthenticated && authUser.role.id === ROLE_ID_SYSTEM;
  const authUserIsPending =
    authUserIsAuthenticated && authUser.status.id === STATUS_ID_PENDING;
  const authUserIsActive =
    authUserIsAuthenticated && authUser.status.id === STATUS_ID_ACTIVE;

  const authUserRoleId = authUserIsAuthenticated ? authUser.role.id : null;

  return {
    authUser,
    authUserRoleId,
    authUserIsAuthenticated,
    authUserHasNoRole,
    authUserHasRole,
    authUserIsSchool,
    authUserIsEngagement,
    authUserIsOfficer,
    authUserIsAdmin,
    authUserIsSystem,
    authUserIsPending,
    authUserIsActive,
  };
};

export default useAuthUser;
