import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import SbatNoteForm from './SbatNoteForm';
import { sbatNotesFetchRequested } from '../../redux/actions';
import Loading from '../Loading';
import Error404 from '../errors/Error404';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  noteBox: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  noteHeader: {},
  noteAuthor: {
    color: theme.palette.text.secondary,
  },
  noteStatus: {
    float: 'right',
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
}));

const SbatNotes = ({ sbatId }) => {
  const dispatch = useDispatch();
  const [note, setNote] = useState({});
  const fetchingSbatNotes = useSelector(
    (state) => state.sbats.fetchingSbatNotes
  );
  const sbatNotFound = useSelector((state) => state.sbats.sbatNotFound);
  const notes = useSelector((state) => state.sbats.notes);
  const notesQuery = useSelector((state) => state.sbats.notesQuery);
  const classes = useStyles();

  useEffect(() => {
    if (!fetchingSbatNotes && !sbatNotFound && sbatId && notes === null) {
      dispatch(sbatNotesFetchRequested(sbatId, notesQuery));
      setNote({ note: '' });
    }
  }, [dispatch, sbatId, fetchingSbatNotes, sbatNotFound, notesQuery, notes]);

  return sbatNotFound ? (
    <Error404 />
  ) : (
    <>
      <Grid className={classes.root} container spacing={1}>
        <Grid item xs={12}>
          <SbatNoteForm sbatId={sbatId} initialNote={note} />
        </Grid>
        <Grid item xs={12}>
          {fetchingSbatNotes || notes === null ? (
            <Loading />
          ) : notes.length === 0 ? (
            <Typography color="textSecondary">No notes</Typography>
          ) : (
            notes.map((note, index) => (
              <Box key={note.id} className={classes.noteBox}>
                <Box className={classes.noteHeader}>
                  <Typography className={classes.noteStatus}>
                    {note.sbat_status.name}
                  </Typography>
                  <Typography className={classes.noteAuthor}>
                    {DateTime.fromISO(note.created_at).toFormat(
                      'dd/LL/yyyy h:mm:ss a'
                    )}
                    : {note.created_by_user.username}
                  </Typography>
                </Box>
                <Box>{note.note}</Box>
              </Box>
            ))
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SbatNotes;
