import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatActivitiesFetchSucceeded } from '../actions';
import { SBAT_ACTIVITIES_FETCH_REQUESTED } from '../actionTypes';

function* fetchSbatActivities(action) {
  const data = yield call(api.fetchSbatActivities, action.sbatId);
  yield put(sbatActivitiesFetchSucceeded(data));
}

function* fetchSbatActivitiesSaga() {
  yield takeLatest(SBAT_ACTIVITIES_FETCH_REQUESTED, fetchSbatActivities);
}

export default fetchSbatActivitiesSaga;
