import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';

import { sbatSaveRequested, userNotify } from '../../../redux/actions';
import EmployerDetails from './sections/EmployerDetails';
import StudentDetails from './sections/StudentDetails';
import SchoolDetails from './sections/SchoolDetails';
import AnpDetails from './sections/AnpDetails';
import TrainingDetails from './sections/TrainingDetails';
import AdditionalInformation from './sections/AdditionalInformation';
import Rto1Details from './sections/Rto1Details';
import Rto2Details from './sections/Rto2Details';
import getTrainingPlanValidationSchema, {
  getTrainingPlanRequiredOnSubmit,
} from './trainingPlanValidationSchema';
import useAuthUser from '../../../hooks/useAuthUser';
import useTrainingPlanOptions from '../../../hooks/useTrainingPlanOptions';
import {
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from '../../../roles';
import {
  SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION,
  SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW,
  SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE,
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  getSbatStatusIndex,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
} from '../../../sbatStatuses';
import { SBAT_ACTION_SAVE, SBAT_ACTION_SUBMIT } from '../../../sbatActions';
import TrainingPlanProgress from './TrainingPlanProgress';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  element: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginRight: theme.spacing(2),
  },
}));

const TrainingPlanForm = ({ initialSbat }) => {
  const classes = useStyles();
  const [saveWithoutValidation, setSaveWithoutValidation] = useState(false);
  const { authUserRoleId } = useAuthUser();
  const { trainingPlanOptions } = useTrainingPlanOptions();
  const requiredOnSubmit = getTrainingPlanRequiredOnSubmit(
    initialSbat.status.id,
    authUserRoleId
  );
  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    errors,
    watch,
    register,
  } = useForm({
    validationSchema: getTrainingPlanValidationSchema(
      initialSbat.status.id,
      authUserRoleId
    ),
    validationContext: { save: saveWithoutValidation },
    defaultValues: {
      // must be array to squash multicheckbox error
      td_mode_of_delivery_ids: initialSbat.td_mode_of_delivery_ids ?? [],
      // Dates need to be cast to luxon Dates
      sd_date_of_birth: initialSbat.sd_date_of_birth
        ? DateTime.fromISO(initialSbat.sd_date_of_birth)
        : null,
      td_tc_start_date: initialSbat.td_tc_start_date
        ? DateTime.fromISO(initialSbat.td_tc_start_date)
        : null,
      td_tc_end_date: initialSbat.td_tc_end_date
        ? DateTime.fromISO(initialSbat.td_tc_end_date)
        : null,
      r1_start_date: initialSbat.r1_start_date
        ? DateTime.fromISO(initialSbat.r1_start_date)
        : null,
      r1_end_date: initialSbat.r1_end_date
        ? DateTime.fromISO(initialSbat.r1_end_date)
        : null,
      r2_start_date: initialSbat.r2_start_date
        ? DateTime.fromISO(initialSbat.r2_start_date)
        : null,
      r2_end_date: initialSbat.r2_end_date
        ? DateTime.fromISO(initialSbat.r2_end_date)
        : null,
      shouldFocusError: true,
    },
    reValidateMode: 'onSubmit',
  });
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const roleGreaterThanSchool = [
    ROLE_ID_SBAT_OFFICER,
    ROLE_ID_ADMIN,
    ROLE_ID_SYSTEM,
  ].includes(authUserRoleId);

  useEffect(() => {
    if (saveWithoutValidation) {
      if (!savingSbat) {
        handleSubmit(onSave)();
      }
      setSaveWithoutValidation(false);
    }
  }, [saveWithoutValidation, savingSbat]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(userNotify('Training plan could not be progressed', false));
    }
  }, [errors]);

  const onSave = (data) => {
    dispatch(
      sbatSaveRequested({
        id: initialSbat.id,
        data: {
          ...data,
        },
        action: SBAT_ACTION_SAVE,
      })
    );
  };

  const onSubmit = (data) => {
    dispatch(
      sbatSaveRequested({
        id: initialSbat.id,
        data: {
          ...data,
        },
        action: SBAT_ACTION_SUBMIT,
      })
    );
  };

  return (
    trainingPlanOptions && (
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <StudentDetails
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                initialSbat.can_progress ||
                  (roleGreaterThanSchool &&
                    getSbatStatusIndex(initialSbat.status.id) >=
                      getSbatStatusIndex(
                        SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                      ) &&
                    getSbatStatusIndex(initialSbat.status.id) <
                      getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE))
              )}
            />
            <SchoolDetails
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                initialSbat.can_progress ||
                  (roleGreaterThanSchool &&
                    getSbatStatusIndex(initialSbat.status.id) >=
                      getSbatStatusIndex(
                        SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                      ) &&
                    getSbatStatusIndex(initialSbat.status.id) <
                      getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE))
              )}
            />
            <EmployerDetails
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                initialSbat.can_progress ||
                  (roleGreaterThanSchool &&
                    getSbatStatusIndex(initialSbat.status.id) >=
                      getSbatStatusIndex(
                        SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                      ) &&
                    getSbatStatusIndex(initialSbat.status.id) <
                      getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE))
              )}
            />
            <AnpDetails
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                (initialSbat.can_progress || roleGreaterThanSchool) &&
                  getSbatStatusIndex(initialSbat.status.id) >=
                    getSbatStatusIndex(
                      SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                    ) &&
                  getSbatStatusIndex(initialSbat.status.id) <
                    getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE)
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TrainingDetails
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                (initialSbat.can_progress || roleGreaterThanSchool) &&
                  getSbatStatusIndex(initialSbat.status.id) >=
                    getSbatStatusIndex(
                      SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                    ) &&
                  getSbatStatusIndex(initialSbat.status.id) <
                    getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE)
              )}
            />
            <AdditionalInformation
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                (initialSbat.can_progress || roleGreaterThanSchool) &&
                  getSbatStatusIndex(initialSbat.status.id) >=
                    getSbatStatusIndex(
                      SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION
                    ) &&
                  getSbatStatusIndex(initialSbat.status.id) <
                    getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE)
              )}
            />
            <Rto1Details
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                roleGreaterThanSchool &&
                  getSbatStatusIndex(initialSbat.status.id) >=
                    getSbatStatusIndex(
                      SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW
                    ) &&
                  getSbatStatusIndex(initialSbat.status.id) <
                    getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE)
              )}
            />
            <Rto2Details
              initialSbat={initialSbat}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              register={register}
              trainingPlanOptions={trainingPlanOptions}
              requiredOnSubmit={requiredOnSubmit}
              enabled={Boolean(
                roleGreaterThanSchool &&
                  getSbatStatusIndex(initialSbat.status.id) >=
                    getSbatStatusIndex(
                      SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW
                    ) &&
                  getSbatStatusIndex(initialSbat.status.id) <
                    getSbatStatusIndex(SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE)
              )}
            />
          </Grid>
        </Grid>
        {initialSbat.can_progress && (
          <Box className={classes.updateButtonBox}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={savingSbat}
              className={classes.submit}
              onClick={(e) => {
                e.preventDefault();
                setSaveWithoutValidation(true);
              }}
            >
              Save
            </Button>
            {(getSbatStatusIndex(initialSbat.status.id) <
              getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW) ||
              initialSbat.status.id ===
                SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW) && (
              <TrainingPlanProgress
                savingSbat={savingSbat}
                control={control}
                register={register}
                sbat={initialSbat}
                onProgress={handleSubmit(onSubmit)}
              />
            )}
          </Box>
        )}
      </form>
    )
  );
};

export default TrainingPlanForm;
