import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatNotesFetchSucceeded } from '../actions';
import { SBAT_NOTES_FETCH_REQUESTED } from '../actionTypes';

function* fetchSbatNotes(action) {
  const res = yield call(api.fetchSbatNotes, action.sbatId, action.query);
  yield put(sbatNotesFetchSucceeded(res));
}

function* fetchSbatNotesSaga() {
  yield takeLatest(SBAT_NOTES_FETCH_REQUESTED, fetchSbatNotes);
}

export default fetchSbatNotesSaga;
