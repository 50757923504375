import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sbatSaveRequested } from '../../redux/sbats/actions';
import Breadcrumbs from '../Breadcrumbs';
import SbatAddForm from './SbatAddForm';

const AddSbat = () => {
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const onSubmit = (data) => {
    dispatch(
      sbatSaveRequested({
        data: {
          student_id: data.student.id,
          employer_id: data.employer.id,
          qualification_id: data.qualification.id,
          td_vocation_title: data.qualification.data.vocation_title,
          td_vto_id: data.qualification.data.vto_id,
          td_qualification_title: data.qualification.data.qualification_title,
          td_qualification_level: data.qualification.data.qualification_level,
          td_national_code: data.qualification.data.national_code,
          consent_declaration: data.consent_declaration,
        },
      })
    );
  };

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { text: 'SBATs', to: '/sbats', key: 'sbats' },
          { text: 'Add SBAT', key: 'add-sbat' },
        ]}
      />
      <SbatAddForm
        title="Add SBAT"
        onSubmit={onSubmit}
        disabled={savingSbat}
        initialSbat={{}}
      />
    </>
  );
};

export default AddSbat;
