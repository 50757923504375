import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../form/TextInput';
import SelectInput from '../../../form/SelectInput';
import SelectBooleanInput from '../../../form/SelectBooleanInput';
import TrainingPlanSection from '../TrainingPlanSection';
import AutoCompleteInputAsync from '../../../form/AutoCompleteInputAsync';
import api from '../../../../api';
import { Button } from '@material-ui/core';

const EmployerDetails = ({
  initialSbat,
  watch,
  setValue,
  getValues,
  control,
  errors,
  register,
  trainingPlanOptions,
  enabled,
  disableContactLookup,
  requiredOnSubmit,
}) => {
  const selectedContact = !disableContactLookup
    ? watch('ed_contact_email')
    : null;

  useEffect(() => {
    if (
      !disableContactLookup &&
      selectedContact &&
      selectedContact.data &&
      (!initialSbat.ed_contact_email ||
        initialSbat.ed_contact_email !== selectedContact.data.email)
    ) {
      setValue([
        { ed_contact_name: selectedContact.data.name || '' },
        { ed_contact_phone: selectedContact.data.phone || '' },
        { ed_contact_fax: selectedContact.data.fax || '' },
        { ed_contact_mobile: selectedContact.data.mobile || '' },
      ]);
    }
  }, [selectedContact, initialSbat]);

  const handleCopyWorkplaceAddress = () => {
    setValue([
      { ed_workplace_training_street_address: getValues('ed_street_address') },
      { ed_workplace_training_suburb: getValues('ed_suburb') },
      { ed_workplace_training_state_id: getValues('ed_state_id') },
      { ed_workplace_training_postcode: getValues('ed_postcode') },
    ]);
  };

  return (
    <>
      <TrainingPlanSection title="Employer Details">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Legal name"
              name="ed_legal_name"
              defaultValue={initialSbat.ed_legal_name || ''}
              control={control}
              hasError={Boolean(errors.ed_legal_name)}
              errorText={errors?.ed_legal_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_legal_name}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Trading name"
              name="ed_trading_name"
              defaultValue={initialSbat.ed_trading_name || ''}
              control={control}
              hasError={Boolean(errors.ed_trading_name)}
              errorText={errors?.ed_trading_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_trading_name}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="ABN"
              name="ed_abn"
              defaultValue={initialSbat.ed_abn || ''}
              control={control}
              hasError={Boolean(errors.ed_abn)}
              errorText={errors?.ed_abn?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_abn}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              label="Street Address"
              name="ed_street_address"
              defaultValue={initialSbat.ed_street_address || ''}
              control={control}
              hasError={Boolean(errors.ed_street_address)}
              errorText={errors?.ed_street_address?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_street_address}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Suburb"
              name="ed_suburb"
              defaultValue={initialSbat.ed_suburb || ''}
              control={control}
              hasError={Boolean(errors.ed_suburb)}
              errorText={errors?.ed_suburb?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_suburb}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="State"
              name="ed_state_id"
              options={trainingPlanOptions.states}
              control={control}
              defaultValue={initialSbat.ed_state_id || ''}
              hasError={Boolean(errors.ed_state_id)}
              errorText={errors?.ed_state_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_state_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Postcode"
              name="ed_postcode"
              defaultValue={initialSbat.ed_postcode || ''}
              control={control}
              hasError={Boolean(errors.ed_postcode)}
              errorText={errors?.ed_postcode?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_postcode}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {!disableContactLookup ? (
              <AutoCompleteInputAsync
                label="Contact Email"
                key="ed_contact_email"
                name="ed_contact_email"
                defaultValue={initialSbat.ed_contact_email || ''}
                control={control}
                hasError={Boolean(errors.ed_contact_email)}
                errorText={errors?.ed_contact_email?.message}
                freeSolo
                autoSelect
                onSuggest={api.suggestContacts}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.ed_contact_email}
              />
            ) : (
              <TextInput
                name="ed_contact_email"
                label="Contact Email"
                defaultValue={initialSbat.ed_contact_email || ''}
                control={control}
                hasError={Boolean(errors.ed_contact_email)}
                errorText={errors?.ed_contact_email?.message}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.ed_contact_email}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="ed_contact_name"
              label="Contact Name"
              defaultValue={initialSbat.ed_contact_name || ''}
              control={control}
              hasError={Boolean(errors.ed_contact_name)}
              errorText={errors?.ed_contact_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_contact_name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ed_contact_phone"
              label="Contact Phone"
              defaultValue={initialSbat.ed_contact_phone || ''}
              control={control}
              hasError={Boolean(
                errors.ed_contact_phone || errors.ed_contact_phone_or_mobile
              )}
              errorText={
                errors?.ed_contact_phone?.message ??
                errors?.ed_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ed_contact_mobile"
              label="Contact Mobile"
              defaultValue={initialSbat.ed_contact_mobile || ''}
              control={control}
              hasError={Boolean(
                errors.ed_contact_mobile || errors.ed_contact_phone_or_mobile
              )}
              errorText={
                errors?.ed_contact_mobile?.message ??
                errors?.ed_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ed_contact_fax"
              label="Contact Fax"
              defaultValue={initialSbat.ed_contact_fax || ''}
              control={control}
              hasError={Boolean(errors.ed_contact_fax)}
              errorText={errors?.ed_contact_fax?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_contact_fax}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {enabled && (
            <Grid item xs={12} lg={3}>
              <Button
                onClick={handleCopyWorkplaceAddress}
                variant="outlined"
                color="primary"
                disabled={!enabled}
              >
                Copy employer address
              </Button>
            </Grid>
          )}
          <Grid item xs={12} lg={enabled ? 9 : 12}>
            <TextInput
              name="ed_workplace_training_street_address"
              label="Workplace Training Street Address"
              defaultValue={
                initialSbat.ed_workplace_training_street_address || ''
              }
              control={control}
              hasError={Boolean(errors.ed_workplace_training_street_address)}
              errorText={errors?.ed_workplace_training_street_address?.message}
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.ed_workplace_training_street_address
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ed_workplace_training_suburb"
              label="Workplace Training Suburb"
              defaultValue={initialSbat.ed_workplace_training_suburb || ''}
              control={control}
              hasError={Boolean(errors.ed_workplace_training_suburb)}
              errorText={errors?.ed_workplace_training_suburb?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_workplace_training_suburb}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="Workplace Training State"
              name="ed_workplace_training_state_id"
              options={trainingPlanOptions.states}
              control={control}
              defaultValue={initialSbat.ed_workplace_training_state_id || ''}
              hasError={Boolean(errors.ed_workplace_training_state_id)}
              errorText={errors?.ed_workplace_training_state_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_workplace_training_state_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ed_workplace_training_postcode"
              label="Workplace Training Postcode"
              defaultValue={initialSbat.ed_workplace_training_postcode || ''}
              control={control}
              hasError={Boolean(errors.ed_workplace_training_postcode)}
              errorText={errors?.ed_workplace_training_postcode?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_workplace_training_postcode}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="ed_workplace_supervisor_name"
              label="Workplace Supervisor Name"
              defaultValue={initialSbat.ed_workplace_supervisor_name || ''}
              control={control}
              hasError={Boolean(errors.ed_workplace_supervisor_name)}
              errorText={errors?.ed_workplace_supervisor_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_workplace_supervisor_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="ed_workplace_supervisor_contact_number"
              label="Workplace Supervisor Contact Number"
              defaultValue={
                initialSbat.ed_workplace_supervisor_contact_number || ''
              }
              control={control}
              hasError={Boolean(errors.ed_workplace_supervisor_contact_number)}
              errorText={
                errors?.ed_workplace_supervisor_contact_number?.message
              }
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.ed_workplace_supervisor_contact_number
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="ed_regulated_trades_supervisor_name"
              label="Regulated Trades Supervisor Name"
              defaultValue={
                initialSbat.ed_regulated_trades_supervisor_name || ''
              }
              control={control}
              hasError={Boolean(errors.ed_regulated_trades_supervisor_name)}
              errorText={errors?.ed_regulated_trades_supervisor_name?.message}
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.ed_regulated_trades_supervisor_name
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="ed_regulated_trades_supervisor_license_no"
              label="Regulated Trades License Number"
              defaultValue={
                initialSbat.ed_regulated_trades_supervisor_license_no || ''
              }
              control={control}
              hasError={Boolean(
                errors.ed_regulated_trades_supervisor_license_no
              )}
              errorText={
                errors?.ed_regulated_trades_supervisor_license_no?.message
              }
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.ed_regulated_trades_supervisor_license_no
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <SelectBooleanInput
              name="ed_host_employer"
              label="Host Employer"
              control={control}
              defaultValue={initialSbat.ed_host_employer}
              hasError={Boolean(errors.ed_host_employer)}
              errorText={errors?.ed_host_employer?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_host_employer}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <TextInput
              name="ed_host_employer_trading_name"
              label="Host Employer Trading Name"
              defaultValue={initialSbat.ed_host_employer_trading_name || ''}
              control={control}
              hasError={Boolean(errors.ed_host_employer_trading_name)}
              errorText={errors?.ed_host_employer_trading_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ed_host_employer_trading_name}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default EmployerDetails;
