export const EXTERNAL_SBAT_FETCH_REQUESTED = 'EXTERNAL_SBAT_FETCH_REQUESTED';
export const EXTERNAL_SBAT_FETCH_SUCCEEDED = 'EXTERNAL_SBAT_FETCH_SUCCEEDED';
export const EXTERNAL_SBAT_NOT_FOUND = 'EXTERNAL_SBAT_NOT_FOUND';

export const EXTERNAL_SBAT_SAVE_REQUESTED = 'EXTERNAL_SBAT_SAVE_REQUESTED';
export const EXTERNAL_SBAT_SAVE_SUCCEEDED = 'EXTERNAL_SBAT_SAVE_SUCCEEDED';

export const EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED =
  'EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED';
export const EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED =
  'EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED';
