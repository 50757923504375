import {
  EXTERNAL_SBAT_FETCH_REQUESTED,
  EXTERNAL_SBAT_FETCH_SUCCEEDED,
  EXTERNAL_SBAT_NOT_FOUND,
  EXTERNAL_SBAT_SAVE_REQUESTED,
  EXTERNAL_SBAT_SAVE_SUCCEEDED,
  EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED,
  EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
} from './actionTypes';

export const externalSbatFetchRequested = (id, token) => ({
  type: EXTERNAL_SBAT_FETCH_REQUESTED,
  id,
  token,
});

export const externalSbatFetchSucceeded = ({ user, sbat }) => ({
  type: EXTERNAL_SBAT_FETCH_SUCCEEDED,
  user,
  sbat,
});

export const externalSbatNotFound = () => ({
  type: EXTERNAL_SBAT_NOT_FOUND,
});

export const externalSbatSaveRequested = ({ id, data, action, token }) => ({
  type: EXTERNAL_SBAT_SAVE_REQUESTED,
  id,
  data,
  action,
  token,
});

export const externalSbatSaveSucceeded = () => ({
  type: EXTERNAL_SBAT_SAVE_SUCCEEDED,
});

export const externalUploadTrainingPlanRequested = ({ id, file, token }) => ({
  type: EXTERNAL_UPLOAD_TRAINING_PLAN_REQUESTED,
  id,
  file,
  token,
});

export const externalUploadTrainingPlanProposalRequested = ({
  id,
  file,
  token,
}) => ({
  type: EXTERNAL_UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
  id,
  file,
  token,
});
