import {
  SBATS_FETCH_REQUESTED,
  SBATS_FETCH_SUCCEEDED,
  SBAT_SAVE_REQUESTED,
  SBAT_SAVE_SUCCEEDED,
  SBAT_FETCH_REQUESTED,
  SBAT_FETCH_SUCCEEDED,
  SBAT_NOT_FOUND,
  SBAT_ACTIVITIES_FETCH_REQUESTED,
  SBAT_ACTIVITIES_FETCH_SUCCEEDED,
  SBAT_NOTES_FETCH_REQUESTED,
  SBAT_NOTES_FETCH_SUCCEEDED,
  SBAT_NOTE_SAVE_REQUESTED,
  SBAT_NOTE_SAVE_SUCCEEDED,
  UPDATE_SBATS_QUERY_SORT,
  UPDATE_SBATS_QUERY_SEARCH,
  UPDATE_SBATS_QUERY_FILTER,
  RESET_SBATS_QUERY,
  SET_SBATS_QUERY,
  PENDING_SBATS_FETCH_REQUESTED,
  PENDING_SBATS_FETCH_SUCCEEDED,
  AUTH_USER_PROFILE_SAVE_SUCCEEDED,
  TRAINING_PLAN_OPTIONS_FETCH_REQUESTED,
  TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED,
  AUTH_USER_IMPERSONATE_SUCCEEDED,
} from '../actionTypes';

const initialState = {
  sbats: null,
  fetchingSbats: false,
  query: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'updated_at',
      asc: false,
    },
  },
  sbat: null,
  fetchingSbat: false,
  savingSbat: false,
  notes: null,
  notesQuery: {
    cursor: null,
    filters: [],
    search: '',
    sort: {
      by: 'created_at',
      asc: false,
    },
  },
  activities: null,
  fetchingSbatActivities: false,
  fetchingSbatNotes: false,
  savingSbatNote: false,
  sbatNotFound: false,
  pendingSbats: null,
  fetchingPendingSbats: false,
  trainingPlanOptions: null,
  fetchingTrainingPlanOptions: false,
};

const sbatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SBATS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSbats: true,
      };
    }

    case SBATS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSbats: false,
        sbats:
          state.sbats === null
            ? action.sbats
            : [...state.sbats, ...action.sbats], // append
        query: { ...state.query, cursor: action.next },
      };
    }

    case UPDATE_SBATS_QUERY_SORT: {
      return {
        ...state,
        fetchingSbats: false,
        sbats: null,
        query: {
          ...state.query,
          cursor: null,
          sort: { by: action.id, asc: action.asc },
        },
      };
    }

    case UPDATE_SBATS_QUERY_SEARCH: {
      return {
        ...state,
        fetchingSbats: false,
        sbats: null,
        query: {
          ...state.query,
          cursor: null,
          search: action.search,
        },
      };
    }

    case UPDATE_SBATS_QUERY_FILTER: {
      return {
        ...state,
        fetchingSbats: false,
        sbats: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {
            ...state.query.filters,
            [action.filterId]: action.optionValue,
          },
        },
      };
    }

    case RESET_SBATS_QUERY: {
      return {
        ...state,
        fetchingSbats: false,
        sbats: null,
        query: {
          ...state.query,
          cursor: null,
          filters: {},
          search: '',
        },
      };
    }

    case SET_SBATS_QUERY: {
      return {
        ...state,
        fetchingSbats: false,
        sbats: null,
        query: {
          ...initialState.query,
          ...action.query,
        },
      };
    }

    case SBAT_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSbat: true,
        sbat: null,
        sbatNotFound: false,
        notes: null,
        notesQuery: { ...state.notesQuery, cursor: null },
      };
    }

    case SBAT_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSbat: false,
        sbat: action.sbat,
      };
    }

    case SBAT_NOT_FOUND: {
      return {
        ...state,
        fetchingSbat: false,
        sbatNotFound: true,
      };
    }

    case SBAT_SAVE_REQUESTED: {
      return {
        ...state,
        savingSbat: true,
      };
    }
    case SBAT_SAVE_SUCCEEDED: {
      // Need to reload all sbats if saved
      return {
        ...state,
        sbats: null,
        sbat: null,
        activities: null,
        query: {
          ...state.query,
          cursor: null,
        },
        savingSbat: false,
      };
    }

    case SBAT_ACTIVITIES_FETCH_REQUESTED: {
      return {
        ...state,
        activities: null,
        fetchingSbatActivities: true,
      };
    }

    case SBAT_ACTIVITIES_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSbatActivities: false,
        activities: action.activities,
      };
    }

    case SBAT_NOTES_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingSbatNotes: true,
      };
    }

    case SBAT_NOTES_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingSbatNotes: false,
        notes:
          state.notes === null
            ? action.notes
            : [...state.notes, ...action.notes], // append
        notesQuery: { ...state.notesQuery, cursor: action.next },
      };
    }

    case SBAT_NOTE_SAVE_REQUESTED: {
      return {
        ...state,
        savingSbatNote: true,
      };
    }

    case SBAT_NOTE_SAVE_SUCCEEDED: {
      return {
        ...state,
        activities: null,
        savingSbatNote: false,
        notes: null,
        notesQuery: { ...state.notesQuery, cursor: null },
      };
    }

    case PENDING_SBATS_FETCH_REQUESTED: {
      return {
        ...state,
        pendingSbats: null,
        fetchingPendingSbats: true,
      };
    }

    case PENDING_SBATS_FETCH_SUCCEEDED: {
      return {
        ...state,
        pendingSbats: action.sbats,
        fetchingPendingSbats: false,
      };
    }

    case AUTH_USER_PROFILE_SAVE_SUCCEEDED: {
      return { ...state, pendingSbats: null };
    }

    case TRAINING_PLAN_OPTIONS_FETCH_REQUESTED: {
      return {
        ...state,
        fetchingTrainingPlanOptions: true,
      };
    }

    case TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED: {
      return {
        ...state,
        fetchingTrainingPlanOptions: false,
        trainingPlanOptions: action.trainingPlanOptions,
      };
    }

    case AUTH_USER_IMPERSONATE_SUCCEEDED: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default sbatsReducer;
