import React from 'react';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../form/TextInput';
import DateInput from '../../../form/DateInput';
import SelectInput from '../../../form/SelectInput';
import SelectBooleanInput from '../../../form/SelectBooleanInput';
import TrainingPlanSection from '../TrainingPlanSection';

const StudentDetails = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  requiredOnSubmit,
  enabled,
}) => {
  return (
    <TrainingPlanSection title="Apprentice/Trainee Personal Details">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="First Name"
            name="sd_first_name"
            defaultValue={initialSbat.sd_first_name || ''}
            control={control}
            hasError={Boolean(errors.sd_first_name)}
            errorText={errors?.sd_first_name?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_first_name}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Last Name"
            name="sd_last_name"
            defaultValue={initialSbat.sd_last_name || ''}
            control={control}
            hasError={Boolean(errors.sd_last_name)}
            errorText={errors?.sd_last_name?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_last_name}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <DateInput
            label="Date of Birth"
            name="sd_date_of_birth"
            defaultValue={initialSbat.sd_date_of_birth || ''}
            control={control}
            hasError={Boolean(errors.sd_date_of_birth)}
            errorText={errors?.sd_date_of_birth?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_date_of_birth}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SelectInput
            label="Gender"
            name="sd_gender_id"
            options={trainingPlanOptions.genders}
            control={control}
            defaultValue={initialSbat.sd_gender_id || ''}
            hasError={Boolean(errors.sd_gender_id)}
            errorText={errors?.sd_gender_id?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_gender_id}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SelectBooleanInput
            label="Aboriginal or Torres Strait Islander origin"
            name="sd_aboriginal_or_torres"
            control={control}
            defaultValue={initialSbat.sd_aboriginal_or_torres ?? ''}
            hasError={Boolean(errors.sd_aboriginal_or_torres)}
            errorText={errors?.sd_aboriginal_or_torres?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_aboriginal_or_torres}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SelectBooleanInput
            label="LLN Required"
            name="sd_lln_required"
            control={control}
            defaultValue={initialSbat.sd_lln_required ?? ''}
            hasError={Boolean(errors.sd_lln_required)}
            errorText={errors?.sd_lln_required?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_lln_required}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <TextInput
            label="NESA Number"
            name="sd_nesa_number"
            defaultValue={initialSbat.sd_nesa_number || ''}
            control={control}
            hasError={Boolean(errors.sd_nesa_number)}
            errorText={errors?.sd_nesa_number?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_nesa_number}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextInput
            label="School Year"
            name="sd_school_year"
            defaultValue={initialSbat.sd_school_year || ''}
            control={control}
            hasError={Boolean(errors.sd_school_year)}
            errorText={errors?.sd_school_year?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_school_year}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Street Address"
            name="sd_street_address"
            defaultValue={initialSbat.sd_street_address || ''}
            control={control}
            hasError={Boolean(errors.sd_street_address)}
            errorText={errors?.sd_street_address?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_street_address}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Suburb"
            name="sd_suburb"
            defaultValue={initialSbat.sd_suburb || ''}
            control={control}
            hasError={Boolean(errors.sd_suburb)}
            errorText={errors?.sd_suburb?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_suburb}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="State"
            name="sd_state_id"
            options={trainingPlanOptions.states}
            control={control}
            defaultValue={initialSbat.sd_state_id || ''}
            hasError={Boolean(errors.sd_state_id)}
            errorText={errors?.sd_state_id?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_state_id}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Postcode"
            name="sd_postcode"
            defaultValue={initialSbat.sd_postcode || ''}
            control={control}
            hasError={Boolean(errors.sd_postcode)}
            errorText={errors?.sd_postcode?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_postcode}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Phone"
            name="sd_phone"
            defaultValue={initialSbat.sd_phone || ''}
            control={control}
            hasError={Boolean(errors.sd_phone || errors.sd_phone_or_mobile)}
            errorText={
              errors?.sd_phone?.message ?? errors?.sd_phone_or_mobile?.message
            }
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_phone}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextInput
            label="Mobile"
            name="sd_mobile"
            defaultValue={initialSbat.sd_mobile || ''}
            control={control}
            hasError={Boolean(errors.sd_mobile || errors.sd_phone_or_mobile)}
            errorText={
              errors?.sd_mobile?.message ?? errors?.sd_phone_or_mobile?.message
            }
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_mobile}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Email"
            name="sd_email"
            defaultValue={initialSbat.sd_email || ''}
            control={control}
            hasError={Boolean(errors.sd_email)}
            errorText={errors?.sd_email?.message}
            disabled={!enabled}
            requiredOnSubmit={requiredOnSubmit.sd_email}
          />
        </Grid>
      </Grid>
    </TrainingPlanSection>
  );
};

export default StudentDetails;
