import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../form/TextInput';
import SelectInput from '../../../form/SelectInput';
import TrainingPlanSection from '../TrainingPlanSection';
import AutoCompleteInputAsync from '../../../form/AutoCompleteInputAsync';
import AutoCompleteInput from '../../../form/AutoCompleteInput';
import api from '../../../../api';

const SchoolDetails = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  requiredOnSubmit,
  enabled,
}) => {
  const selectedContact = watch('hd_od_contact_email');

  useEffect(() => {
    if (
      selectedContact &&
      selectedContact.data &&
      (!initialSbat.hd_od_contact_email ||
        initialSbat.hd_od_contact_email !== selectedContact.data.email)
    ) {
      setValue([
        { hd_od_contact_name: selectedContact.data.name || '' },
        { hd_od_contact_phone: selectedContact.data.phone || '' },
        { hd_od_contact_fax: selectedContact.data.fax || '' },
        { hd_od_contact_mobile: selectedContact.data.mobile || '' },
      ]);
    }
  }, [selectedContact, initialSbat]);

  return (
    <>
      <TrainingPlanSection title="School Details">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              label="Name"
              name="hd_name"
              defaultValue={initialSbat.hd_name || ''}
              control={control}
              hasError={Boolean(errors.hd_name)}
              errorText={errors?.hd_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <TextInput
              label="Suburb"
              name="hd_suburb"
              defaultValue={initialSbat.hd_suburb || ''}
              control={control}
              hasError={Boolean(errors.hd_suburb)}
              errorText={errors?.hd_suburb?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_suburb}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="State"
              name="hd_state_id"
              options={trainingPlanOptions.states}
              control={control}
              defaultValue={initialSbat.hd_state_id || ''}
              hasError={Boolean(errors.hd_state_id)}
              errorText={errors?.hd_state_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_state_id}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="hd_contact_name"
              label="School Contact Name"
              defaultValue={initialSbat.hd_contact_name || ''}
              control={control}
              hasError={Boolean(errors.hd_contact_name)}
              errorText={errors?.hd_contact_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_contact_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="hd_contact_phone"
              label="School Contact Phone"
              defaultValue={initialSbat.hd_contact_phone || ''}
              control={control}
              hasError={Boolean(errors.hd_contact_phone)}
              errorText={errors?.hd_contact_phone?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_contact_phone}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="hd_contact_email"
              label="School Contact Email"
              defaultValue={initialSbat.hd_contact_email || ''}
              control={control}
              hasError={Boolean(errors.hd_contact_email)}
              errorText={errors?.hd_contact_email?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_contact_email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="School Sector"
              name="hd_school_sector_id"
              options={trainingPlanOptions.schoolSectors}
              control={control}
              defaultValue={initialSbat.hd_school_sector_id || ''}
              hasError={Boolean(errors.hd_school_sector_id)}
              errorText={errors?.hd_school_sector_id?.message}
              disabled={true}
              requiredOnSubmit={requiredOnSubmit.hd_school_sector_id}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <AutoCompleteInput
              options={trainingPlanOptions.operationalDirectorates}
              name="hd_operational_directorate"
              label="Operational Directorate or Diocese"
              control={control}
              defaultValue={initialSbat.hd_operational_directorate || ''}
              hasError={Boolean(errors.hd_operational_directorate)}
              errorText={errors?.hd_operational_directorate?.message}
              disabled={true}
              requiredOnSubmit={requiredOnSubmit.hd_operational_directorate}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <AutoCompleteInputAsync
              label="School Sector Representative Email"
              key="hd_od_contact_email"
              name="hd_od_contact_email"
              defaultValue={initialSbat.hd_od_contact_email || ''}
              control={control}
              hasError={Boolean(errors.hd_od_contact_email)}
              errorText={errors?.hd_od_contact_email?.message}
              freeSolo
              autoSelect
              onSuggest={api.suggestContacts}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_od_contact_email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="hd_od_contact_name"
              label="Contact Name"
              defaultValue={initialSbat.hd_od_contact_name || ''}
              control={control}
              hasError={Boolean(errors.hd_od_contact_name)}
              errorText={errors?.hd_od_contact_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_od_contact_name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="hd_od_contact_phone"
              label="Contact Phone"
              defaultValue={initialSbat.hd_od_contact_phone || ''}
              control={control}
              hasError={Boolean(
                errors.hd_od_contact_phone ||
                  errors.hd_od_contact_phone_or_mobile
              )}
              errorText={
                errors?.hd_od_contact_phone?.message ??
                errors?.hd_od_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_od_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="hd_od_contact_mobile"
              label="Contact Mobile"
              defaultValue={initialSbat.hd_od_contact_mobile || ''}
              control={control}
              hasError={Boolean(
                errors.hd_od_contact_mobile ||
                  errors.hd_od_contact_phone_or_mobile
              )}
              errorText={
                errors?.hd_od_contact_mobile?.message ??
                errors?.hd_od_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_od_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="hd_od_contact_fax"
              label="Contact Fax"
              defaultValue={initialSbat.hd_od_contact_fax || ''}
              control={control}
              hasError={Boolean(errors.hd_od_contact_fax)}
              errorText={errors?.hd_od_contact_fax?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.hd_od_contact_fax}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default SchoolDetails;
