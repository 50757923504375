import React from 'react';
import { useForm } from 'react-hook-form';

import CenteredForm from '../form/CenteredForm';
import SelectStudent from './SelectStudent';
import SelectEmployer from './SelectEmployer';
import SelectQualification from './SelectQualification';
import CheckboxInput from '../form/CheckboxInput';

const SbatAddForm = ({ title, onSubmit, disabled, initialSbat }) => {
  const {
    setValue,
    control,
    register,
    handleSubmit,
    errors,
    watch,
  } = useForm();
  return (
    <CenteredForm
      title={title}
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <SelectStudent
        watch={watch}
        setValue={setValue}
        control={control}
        disabled={disabled}
        errors={errors}
      />
      <SelectEmployer control={control} disabled={disabled} errors={errors} />
      <SelectQualification
        watch={watch}
        setValue={setValue}
        control={control}
        disabled={disabled}
        errors={errors}
      />
      <CheckboxInput
        register={register}
        name="consent_declaration"
        label="I confirm that the employer, student and parent/carer agree to the development of a training plan proposal in order to establish a school-based apprenticeship or traineeship."
      />
    </CenteredForm>
  );
};

export default SbatAddForm;
