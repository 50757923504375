import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { sbatSaveRequested } from '../../../redux/actions';
import useSbatStatuses from '../../../hooks/useSbatStatuses';
import SelectInput from '../../form/SelectInput';
import CheckboxInput from '../../form/CheckboxInput';
import SelectStudent from '../SelectStudent';
import SelectEmployer from '../SelectEmployer';
import TextInput from '../../form/TextInput';

import { SBAT_ACTION_ADMIN } from '../../../sbatActions';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  element: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginRight: theme.spacing(2),
  },
}));

const TrainingPlanAdmin = ({ initialSbat }) => {
  const classes = useStyles();
  const { sbatStatuses } = useSbatStatuses();

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    errors,
  } = useForm();
  const dispatch = useDispatch();

  const savingSbat = useSelector((state) => state.sbats.savingSbat);

  const onSubmit = (data) => {
    dispatch(
      sbatSaveRequested({
        id: initialSbat.id,
        data: {
          status_id: data.status_id,
          generate_training_plan: data.generate_training_plan,
          generate_training_plan_proposal: data.generate_training_plan_proposal,
          student_id: data.student.id,
          employer_id: data.employer.id,
          tcid: data.tcid,
          consent_declaration: data.consent_declaration,
        },
        action: SBAT_ACTION_ADMIN,
      })
    );
  };

  return (
    sbatStatuses && (
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <SelectInput
              label="SBAT Status"
              name="status_id"
              options={sbatStatuses}
              control={control}
              defaultValue={initialSbat.status.id || ''}
              hasError={Boolean(errors.status_id)}
              errorText={errors.status_id && errors.status_id.message}
              disabled={savingSbat}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CheckboxInput
              register={register}
              defaultValue={false}
              name="generate_training_plan"
              label="Generate Training Plan"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectStudent
              initialStudent={initialSbat.student}
              watch={watch}
              setValue={setValue}
              control={control}
              disabled={savingSbat}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CheckboxInput
              register={register}
              defaultValue={false}
              name="generate_training_plan_proposal"
              label="Generate TPP"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectEmployer
              initialEmployer={initialSbat.employer}
              control={control}
              disabled={savingSbat}
              errors={errors}
            />
          </Grid>
          <Grid item xs={false} lg={6} />
          <Grid item xs={12} lg={6}>
            <TextInput
              name="tcid"
              label="TCID"
              defaultValue={initialSbat.tcid}
              control={control}
              hasError={Boolean(errors.tcid)}
              errorText={errors.tcid && errors.tcid.message}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CheckboxInput
              register={register}
              defaultValue={initialSbat.consent_declaration}
              name="consent_declaration"
              label="I confirm that the employer, student and parent/carer agree to the development of a training plan proposal in order to establish a school-based apprenticeship or traineeship."
            />
          </Grid>
        </Grid>
        <Box className={classes.updateButtonBox}>
          <Button
            type="submit"
            name="submit"
            variant="contained"
            color="secondary"
            disabled={savingSbat}
            className={classes.submit}
          >
            Submit
          </Button>
        </Box>
      </form>
    )
  );
};

export default TrainingPlanAdmin;
