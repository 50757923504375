import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import Form from '../form/Form';
import TextInput from '../form/TextInput';
import getValidationSchema, { textValidation } from '../form/ValidationSchema';
import { sbatNoteSaveRequested } from '../../redux/actions';

const SbatNoteForm = ({ disabled, sbatId, initialNote }) => {
  const schema = {
    note: {
      validation: textValidation,
      required: true,
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
    },
  });

  // clear note after submission
  useEffect(() => {
    if (initialNote) {
      control.setValue('note', initialNote.note);
    }
  }, [initialNote, control]);

  const onSubmit = (data) => {
    dispatch(
      sbatNoteSaveRequested({
        sbatId,
        data,
      })
    );
  };
  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <TextInput
        name="note"
        label="Note"
        //defaultValue={initialNote?.note}
        control={control}
        multiline
        rows={3}
        hasError={Boolean(errors.note)}
        errorText={errors?.note?.message}
      />
    </Form>
  );
};

export default SbatNoteForm;
