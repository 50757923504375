import React from 'react';
import { DateTime } from 'luxon';

import DetailsList from '../DetailsList';

const SbatDetails = ({ sbat }) => {
  return (
    <DetailsList
      details={[
        {
          label: 'Student',
          text: `${sbat.student.name.first} ${sbat.student.name.last}`,
        },
        {
          label: 'DOB',
          text: DateTime.fromISO(sbat.student.date_of_birth).toFormat(
            'dd/LL/yyyy'
          ),
        },
        {
          label: 'School',
          text: sbat.student.school.name,
        },
        {
          label: 'School year',
          text: sbat.student.school_year,
        },
        {
          label: 'HSC year',
          text: sbat.td_sbat_hsc_year,
        },
        {
          label: 'Qualification',
          text: sbat.td_qualification_title,
        },
        {
          label: 'TQI team',
          text: sbat.student.school.rto.name
            ? sbat.student.school.rto.name
            : 'None',
        },
        {
          label: 'Employer',
          text: sbat.employer?.legal_name,
        },
        {
          label: 'Status',
          text: sbat.status.name,
        },
      ]}
    />
  );
};

export default SbatDetails;
