import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import { sbatSaveSucceeded, userNotify } from '../actions';
import { UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED } from '../actionTypes';

function* uploadTrainingPlanProposal({ id, file }) {
  yield call(api.uploadTrainingPlanProposal, id, file);
  yield put(sbatSaveSucceeded());
  yield put(userNotify('Training plan updated successfully'));
}

function* uploadTrainingPlanProposalSaga() {
  yield takeLatest(
    UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
    uploadTrainingPlanProposal
  );
}

export default uploadTrainingPlanProposalSaga;
