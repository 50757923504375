import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  sbatsFetchRequested,
  updateSbatsQuerySort,
  updateSbatsQuerySearch,
  updateSbatsQueryFilter,
  resetSbatsQuery,
} from '../../redux/sbats/actions';
import ResourceTableButtonGroup from '../resourceTable/ResourceTableButtonGroup';
import Breadcrumbs from '../Breadcrumbs';
import config from '../../config';
import ResourceTable from '../resourceTable/ResourceTable';
import {
  FILTER_TYPE_SELECT,
  FILTER_TYPE_SELECT_MULTI,
  FILTER_TYPE_SELECT_AUTOCOMPLETE,
  FILTER_TYPE_CHECKBOX,
} from '../resourceTable/filterTypes';
import useSchools from '../../hooks/useSchools';
import useRtos from '../../hooks/useRtos';
import useAuthUser from '../../hooks/useAuthUser';
import useEmployers from '../../hooks/useEmployers';
import useSbatStatuses from '../../hooks/useSbatStatuses';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Sbats = () => {
  const classes = useStyles();
  const {
    authUserIsSchool,
    authUserIsOfficer,
    authUserIsAdmin,
    authUserIsSystem,
  } = useAuthUser();
  const { schoolsUserCanView } = useSchools();
  const { rtos } = useRtos();
  const { employers } = useEmployers();
  const { sbatStatuses } = useSbatStatuses();
  const school_filters = useSelector(
    (state) => state.apiConfig.config.school_filters
  );

  return (
    <>
      <Box className={classes.topBox}>
        <Breadcrumbs crumbs={[{ text: 'SBATs Establishment', key: 'sbats' }]} />
        <Button
          component={Link}
          to="/sbats/add"
          variant="contained"
          color="secondary"
        >
          Add SBAT
        </Button>
      </Box>

      <ResourceTable
        headers={[
          { id: 'student', label: 'Student', sortable: false },
          { id: 'schools.name', label: 'School', sortable: true },
          { id: 'schools.rto_id', label: 'TQI team', sortable: false },
          { id: 'employers.trading_name', label: 'Employer', sortable: true },
          { id: 'status', label: 'Status', sortable: false },
          { id: 'actions', label: '', sortable: false },
        ]}
        filters={[
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'school_id',
            label: 'School',
            type: FILTER_TYPE_SELECT_AUTOCOMPLETE,
            options: schoolsUserCanView.map((school) => {
              return {
                label: school.name,
                value: school.id,
              };
            }),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'school_performance_directorate',
            label: 'School performance directorate',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.school_performance_directorate.map(
              (val) => {
                return {
                  label: val,
                  value: val,
                };
              }
            ),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'school_principal_network',
            label: 'School principal network',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.school_principal_network.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'asgs_remoteness',
            label: 'ASGS remoteness',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.asgs_remoteness.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'epp_region',
            label: 'EPP region',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.epp_region.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'epp_school_group',
            label: 'EPP school group',
            type: FILTER_TYPE_SELECT_MULTI,
            options: school_filters.epp_school_group.map((val) => {
              return {
                label: val,
                value: val,
              };
            }),
          },
          (authUserIsAdmin || authUserIsSystem || authUserIsOfficer) && {
            id: 'rto_id',
            label: 'TQI team',
            type: FILTER_TYPE_SELECT,
            options: rtos.map((rto) => {
              return {
                label: rto.name,
                value: rto.id,
              };
            }),
          },
          {
            id: 'status_id',
            label: 'Status',
            type: FILTER_TYPE_SELECT,
            options: sbatStatuses.map((status) => {
              return {
                label: status.name,
                value: status.id,
              };
            }),
          },
          {
            id: 'employer_id',
            label: 'Employer',
            type: FILTER_TYPE_SELECT_AUTOCOMPLETE,
            options: employers.map((employer) => {
              return {
                label: employer.name,
                value: employer.id,
              };
            }),
          },
          authUserIsOfficer && {
            id: 'my_assigned_schools',
            label: 'My Assigned Schools',
            type: FILTER_TYPE_CHECKBOX,
          },
          authUserIsOfficer && {
            id: 'my_assigned_employers',
            label: 'My Assigned Employers',
            type: FILTER_TYPE_CHECKBOX,
          },
          (authUserIsOfficer || authUserIsSchool) && {
            id: 'require_my_progression',
            label: 'Require my progression',
            type: FILTER_TYPE_CHECKBOX,
          },
        ].filter(Boolean)}
        mapResourceToColumns={(sbat) => {
          return [
            { label: `${sbat.student.name.first} ${sbat.student.name.last}` },
            { label: sbat.student.school.name },
            { label: sbat.student.school.rto.name },
            { label: sbat.employer && sbat.employer.trading_name },
            {
              label: (
                <Box>
                  <Box>{sbat.status.name}</Box>
                  {sbat.waiting_on_my_progression && (
                    <Typography variant="body2" color="error">
                      Waiting on your progression
                    </Typography>
                  )}
                </Box>
              ),
            },
            {
              label: (
                <ResourceTableButtonGroup
                  buttons={[
                    {
                      to: `/sbats/${sbat.id}`,
                      color: 'secondary',
                      text: 'Manage SBAT',
                      key: 'manage-sbat',
                    },
                  ]}
                />
              ),
            },
          ];
        }}
        requestResourceFetch={sbatsFetchRequested}
        isFetchingResourceSelector={(state) => state.sbats.fetchingSbats}
        resourcesSelector={(state) => state.sbats.sbats}
        resourceQuerySelector={(state) => state.sbats.query}
        updateResourceQuerySort={updateSbatsQuerySort}
        updateResourceQuerySearch={updateSbatsQuerySearch}
        updateResourceQueryFilter={updateSbatsQueryFilter}
        resetResourceQuery={resetSbatsQuery}
        exportResourcesUrl={`${config.apiBaseUrl}/sbats/export`}
        searchLabel="Search for SBATs"
      />
    </>
  );
};

export default Sbats;
