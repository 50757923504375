import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import Form from '../form/Form';
import SelectInput from '../form/SelectInput';
import { userSaveRequested } from '../../redux/actions';
import AutoCompleteInput from '../form/AutoCompleteInput';
import MultiAutoCompleteInput from '../form/MultiAutoCompleteInput';
import {
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_ENGAGEMENT,
  ROLE_ID_SBAT_OFFICER,
} from '../../roles';
import useRoles from '../../hooks/useRoles';
import useSchools from '../../hooks/useSchools';
import useRtos from '../../hooks/useRtos';
import useUserStatuses from '../../hooks/useUserStatuses';
import useEmployers from '../../hooks/useEmployers';

const UserForm = ({ initialUser }) => {
  const dispatch = useDispatch();
  const { userStatuses } = useUserStatuses();
  const { rtos } = useRtos();
  const { rolesUserCanView } = useRoles();
  const { schoolsUserCanView } = useSchools();
  const { employers } = useEmployers();
  const savingUser = useSelector((state) => state.users.savingUser);

  const { control, handleSubmit, watch, errors } = useForm();

  const onSubmit = (data) => {
    dispatch(
      userSaveRequested(initialUser.id, {
        role_id: data.role_id,
        status_id: data.status_id,
        school_id: data.school ? data.school.id : null,
        rto_id: data.rto ? data.rto.id : null,
        assigned_schools: data.assigned_schools
          ? data.assigned_schools.map((school) => {
              return school.id;
            })
          : [],
        assigned_employers: data.assigned_employers
          ? data.assigned_employers.map((employer) => {
              return employer.id;
            })
          : [],
      })
    );
  };

  const selectedRoleId = watch('role_id', initialUser.role.id);
  const userSchool = schoolsUserCanView.find((school) => {
    return school.id === (initialUser.school ? initialUser.school.id : null);
  });

  const userRto = rtos.find((rto) => {
    return rto.id === (initialUser.rto ? initialUser.rto.id : null);
  });

  // Need to map the assigned school to the option object even though
  // attributes are identical, otherwise the equality test for what is
  // selected in the autocomplete throws errors
  const assignedSchools = initialUser.assignedSchools.map((assignedSchool) => {
    return schoolsUserCanView.find((school) => {
      return assignedSchool.id === school.id;
    });
  });
  const assignedEmployers = initialUser.assignedEmployers.map(
    (assignedEmployer) => {
      return employers.find((employer) => {
        return assignedEmployer.id === employer.id;
      });
    }
  );

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={savingUser}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectInput
            options={rolesUserCanView}
            control={control}
            disabled={rolesUserCanView.length === 1}
            defaultValue={initialUser.role.id}
            name="role_id"
            label="Role"
            key="role_id"
            rules={{ required: true }}
          />
        </Grid>
        {selectedRoleId === ROLE_ID_SCHOOL && (
          <Grid item xs={12}>
            <AutoCompleteInput
              options={schoolsUserCanView}
              defaultValue={userSchool}
              label="School"
              key="school"
              name="school"
              control={control}
              hasError={Boolean(errors.school)}
              errorText={errors.school && 'Required'}
              rules={{ required: true }}
            />
          </Grid>
        )}
        {selectedRoleId === ROLE_ID_SBAT_OFFICER && (
          <Grid item xs={12}>
            <AutoCompleteInput
              options={rtos}
              label="TQI team"
              defaultValue={userRto}
              key="rto"
              name="rto"
              control={control}
              hasError={Boolean(errors.rto)}
              errorText={errors.rto && 'Required'}
              rules={{ required: true }}
            />
          </Grid>
        )}
        {[ROLE_ID_SBAT_ENGAGEMENT, ROLE_ID_SBAT_OFFICER].includes(
          selectedRoleId
        ) && (
          <Grid item xs={12}>
            <MultiAutoCompleteInput
              options={schoolsUserCanView}
              defaultValue={assignedSchools}
              label="Assigned Schools"
              key="assigned_schools"
              name="assigned_schools"
              control={control}
              hasError={Boolean(errors.assigned_schools)}
              errorText={errors.assigned_schools && 'Error'}
              rules={{ required: false }}
            />
          </Grid>
        )}
        {selectedRoleId === ROLE_ID_SBAT_OFFICER && (
          <Grid item xs={12}>
            <MultiAutoCompleteInput
              options={employers}
              label="Employers"
              defaultValue={assignedEmployers}
              key="assigned_employers"
              name="assigned_employers"
              control={control}
              hasError={Boolean(errors.assigned_employers)}
              errorText={errors.assigned_employers && 'Error'}
              rules={{ required: false }}
            />
          </Grid>
        )}
      </Grid>
      <SelectInput
        options={userStatuses}
        control={control}
        defaultValue={initialUser.status.id}
        name="status_id"
        label="Status"
        key="status_id"
        rules={{ required: true }}
      />
    </Form>
  );
};

export default UserForm;
