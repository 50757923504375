import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sbatActivitiesFetchRequested } from '../../redux/actions';
import Loading from '../Loading';
import Error404 from '../errors/Error404';
import ResourceHistory from '../history/ResourceHistory';

const SbatHistory = ({ sbatId }) => {
  const dispatch = useDispatch();
  const fetchingSbatActivities = useSelector(
    (state) => state.sbats.fetchingSbatActivities
  );
  const sbatNotFound = useSelector((state) => state.sbats.sbatNotFound);
  const activities = useSelector((state) => state.sbats.activities);

  useEffect(() => {
    if (
      !fetchingSbatActivities &&
      !sbatNotFound &&
      sbatId &&
      activities === null
    ) {
      dispatch(sbatActivitiesFetchRequested(sbatId));
    }
  }, [dispatch, sbatId, fetchingSbatActivities, sbatNotFound, activities]);

  return sbatNotFound ? (
    <Error404 />
  ) : fetchingSbatActivities || activities === null ? (
    <Loading />
  ) : (
    <ResourceHistory activities={activities} />
  );
};

export default SbatHistory;
