import React from 'react';
import Grid from '@material-ui/core/Grid';

import SelectInput from '../../../form/SelectInput';
import SelectBooleanInput from '../../../form/SelectBooleanInput';
import TrainingPlanSection from '../TrainingPlanSection';

const AdditionalInformation = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  enabled,
  requiredOnSubmit,
}) => {
  return (
    <>
      <TrainingPlanSection title="Additional Information">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SelectInput
              name="ai_commence_school_year"
              label="Year of School at contract commencement date of
traineeship/apprenticeship"
              options={trainingPlanOptions.commenceSchoolYears}
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.ai_commence_school_year}
              hasError={Boolean(errors.ai_commence_school_year)}
              errorText={errors?.ai_commence_school_year?.message}
              requiredOnSubmit={requiredOnSubmit.ai_commence_school_year}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectBooleanInput
              name="ai_impairment_condition"
              label="Does the student have a disability, impairment, or long-term condition?"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.ai_impairment_condition}
              hasError={Boolean(errors.ai_impairment_condition)}
              errorText={errors?.ai_impairment_condition?.message}
              requiredOnSubmit={requiredOnSubmit.ai_impairment_condition}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectBooleanInput
              name="ai_learning_needs"
              label="Does the student have specific learning needs? If so, the school sector is to provide the individual learning plan to the RTO"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.ai_learning_needs}
              hasError={Boolean(errors.ai_learning_needs)}
              errorText={errors?.ai_learning_needs?.message}
              requiredOnSubmit={requiredOnSubmit.ai_learning_needs}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectBooleanInput
              name="ai_course_atar"
              label="Does the course count in the calculation of an Australian Tertiary Admissions Ranking (ATAR)?"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.ai_course_atar}
              hasError={Boolean(errors.ai_course_atar)}
              errorText={errors?.ai_course_atar?.message}
              requiredOnSubmit={requiredOnSubmit.ai_course_atar}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectBooleanInput
              name="ai_hsc_vet_exam"
              label="Does the student intend to undertake the associated HSC VET examination?"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.ai_hsc_vet_exam}
              hasError={Boolean(errors.ai_hsc_vet_exam)}
              errorText={errors?.ai_hsc_vet_exam?.message}
              requiredOnSubmit={requiredOnSubmit.ai_hsc_vet_exam}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default AdditionalInformation;
